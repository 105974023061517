import { SET_CATEGORIES,SET_PARAINAGE,GET_AVIS,SET_REVIEWS,SET_MESSAGES,SET_CONTENT_PAGE,GET_WATCHER,SET_INVOICES,SET_RATE,SAVE_MISSION,SET_FILES,GET_RECLAMATIONS,MISSIONS_REALISE,MISSIONS_VALIDATE,MISSION_ENCOURS,GET_MISSION,WATCHER_MISSION,GET_DETAIL_MISSION,GET_PATENAIRES,LIST_MISSIONS,Edit_MISSION,GET_WATCHERS,GET_FAQ,SET_CURRENT_RESTAURENT,SET_LIST_ADDRESS,SET_LIST_COMMANDES,SET_CURRENT_COMMANDE,SET_HORAIRE_RESTAURENT } from "../constants/actions";

const INITIAL_STATE = {
    data: [],
    reviews: [],
    rates:[],
    watchers: [],
    watchersbymission: [],
    contents: [],
    detailmission:{},
    partenaires: [],
    addresseslist: [],
    commandeslist: [],
    files: [],
    invoices:[],
    missions:[],
    missionsV:[],
    missionsR:[],
    missionsE:[],
    horaires: [],
    contacts: [],
    avis: [],
    commandeinfo: {},
    mission: {},
    watcher: {},
     reclamations: [],
     parainages: [],
    currentRestau:{
        establishment:{},
        categories:[]
    },
    products:[
    ]
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_CATEGORIES :
            return {...state,data:action.payload}
    case SET_REVIEWS :
            return {...state,reviews:action.payload} 
    case GET_WATCHERS :
            return {...state,watchers:action.payload}
     case GET_WATCHER :
            return {...state,watcher:action.payload}            
    case SET_CONTENT_PAGE :
            return {...state,contents:action.payload}  
    case GET_PATENAIRES :
            return {...state,partenaires:action.payload}  
    case GET_FAQ :
            return {...state,contents:action.payload}  
     case LIST_MISSIONS :
            return {...state,missions:action.payload}
    case MISSIONS_VALIDATE :
            return {...state,missionsV:action.payload}  
     case MISSIONS_REALISE :
            return {...state,missionsR:action.payload}   
    case MISSION_ENCOURS :
            return {...state,missionsE:action.payload}                         
    case SAVE_MISSION :
            return {...state}
    case Edit_MISSION :
            return {...state} 
    case GET_MISSION :
            return {...state,mission:action.payload} 
    case GET_DETAIL_MISSION :
            return {...state,detailmission:action.payload}         
    case WATCHER_MISSION :
            return {...state,watchersbymission:action.payload}   

    case GET_RECLAMATIONS :
            return {...state,reclamations:action.payload}  
    case GET_AVIS :
            return {...state,avis:action.payload}        
    case SET_PARAINAGE :
            return {...state,parainages:action.payload} 
    case SET_MESSAGES :
            return {...state,contacts:action.payload}    
    case SET_FILES :
            return {...state,files:action.payload} 
    case SET_INVOICES :
            return {...state,invoices:action.payload}   
    case SET_RATE :
            return {...state,rates:action.payload}             
    case SET_CURRENT_RESTAURENT :
            return {...state,currentRestau:action.payload}
    case SET_LIST_ADDRESS :
          return {...state,addresseslist:action.payload}
    case SET_LIST_COMMANDES :
          return {...state,commandeslist:action.payload}
    case SET_CURRENT_COMMANDE :
          return {...state,commandeinfo:action.payload}
    case SET_HORAIRE_RESTAURENT :
            return {...state,horaires:action.payload}
        default:
            return state;
    }
}
export default reducer;