import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import {Dashbord,Messages,DashbordHeader } from "../../../components";


const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
   
    const history = useHistory()
 
    
 
    return (
        <div className="myccount  " >
            
            <DashbordHeader />

            <section className="gray-bg main-dashboard-sec dashboard detailmission" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord src='messages' />
                                 
                            </div>


                            <div className='col-md-9'>

                                <div className='tab' id=''>
                                    <Messages />
                                </div>

                             
                             </div>


                              

                    </div>
                    
            </section>


        </div>
    );
}

export default Page;