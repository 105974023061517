import { AUTHENTICATE_USER ,SET_LIST_WATCHERS,SET_CURRENT_RESTAURENT,GET_RECLAMATIONS,SET_USER,SET_LIST_ADDRESS,SET_LIST_COMMANDES,SET_CURRENT_COMMANDE} from "../constants/actions"
import { apiClient } from "./api"
 
export const registerUser = (params,profil,currentlang) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none',
        origin_id:2,
        profile:profil,
        currentlang:currentlang
    }
    return apiClient().post(`/membre/create`, data)
        .then((res) => {

           
            
            if(res.data.success==false){
                alert(res.data?.message)
            }else{

                if(profil=='user'){
                  // store the user

                    setTimeout(function(){
                        dispatch({ type: AUTHENTICATE_USER, payload: res.data?.user })
                        window.location.href='/my-account/';
                     }, 1000);
                        alert(res.data?.message)
                        dispatch({ type: AUTHENTICATE_USER, payload: res.data?.user })



                        window.location.href='/my-account/';
                }
                else{
                  // store the watcher
                     
                    window.location.href='/check-your-email/';

                }
                

              
            }
        })
}
export const updateInfoUser = (params,iduser,profil) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none',
        mr_id:null,
        origin_id:3,
        id:iduser,
        profile:profil,
    }
    return apiClient().post(`/membre/updateUserInfos`, data)
        .then((res) => {
            
            if(res.data.success==false){
                alert(res.data.result?.message)
            }else{

                dispatch({ type: AUTHENTICATE_USER, payload: res.data?.customer })
                window.location.reload();
            }
        })
}
export const loginUser = (params,profil,currentlang) => dispatch => {
      const data = {
        ...params,
        profile:profil,
        currentlang:currentlang
    }
    return apiClient().post(`/membre/login`, data)
        .then((res) => {
            
            if(res.data.success==false){
                alert(res.data?.message)
            }else{
                dispatch({ type: AUTHENTICATE_USER, payload:res.data?.user})
                if(profil=='user'){
                   window.location.reload();
                }
                else{
                    window.location.href='/watchers/dashboard'
                }
                
            }
        })
}


export const registerUserMission = (params,profil,currentlang,formDataObj,price,categoryitem,villeitem) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none',
        origin_id:2,
        profile:profil,
        currentlang:currentlang
    }
    return apiClient().post(`/membre/create`, data)
        .then((res) => {
            
            if(res.data.success==false){
                alert(res.data?.message)
            }else{

              
                  // store the user
                        dispatch({ type: AUTHENTICATE_USER, payload: res.data?.user })
                        
                        // save mission 

                const data = {
                    ...formDataObj,
                    token:'',
                    referer:'none',
                    origin_id:2,
                    price:price,
                    categorie:categoryitem,
                    ville:villeitem,
                    user:res.data?.user.id,
                    currentlang:currentlang
                }
                return apiClient().post(`/mission/save`, data)
                    .then((res) => {
                       
                        if(res.data.success==false){
                             alert(res.data?.message)
                             window.location.reload();
                        }else{
                                // remove storage
                                window.location.href='/my-account/';
                          
                        }
                    })
             
             
                

              
            }
        })
}

export const loginUserMission = (user) => dispatch => {
    dispatch({ type: AUTHENTICATE_USER, payload:user})
}

export const loginUserFacebook = (res) => dispatch => {

    dispatch({ type: AUTHENTICATE_USER, payload:res.data?.user})
     window.location.reload();
}


export const getInfoUserById = (id) => dispatch => {
    return apiClient().get(`/getUser/${id}`)
        .then((res) => {
            dispatch({type:SET_LIST_ADDRESS,payload:res.data})
        })
}
export const UpdateUserAddresse = (params) => dispatch => {
    const data = {params}
    return apiClient().post(`/addresses`, params)
        .then((res) => {
            console.log("registerUser res", res.data)
            if(res.data.result?.error){
                alert(res.data.result?.message)
            }else{
                 dispatch(getInfoUserById(params.customer_id));
                alert("Modification effectuée");
            }
        })
}
export const UpdateUserAddresseInf = (params) => dispatch => {
    const data = {params}
    return apiClient().post(`/updateAdresse`, params)
        .then((res) => {
            console.log("registerUser res", res.data)
            if(res.data.result?.error){
                alert(res.data.result?.message)
            }else{
                 dispatch(getInfoUserById(params.customer_id));
                alert("Modification effectuée");
            }
        })
}
export const getCommandeById = (id) => dispatch => {
    return apiClient().get(`/getcommande/${id}`)
        .then((res) => {
            dispatch({type:SET_LIST_COMMANDES,payload:res.data})
        })
}
export const getOneCommandeById = (id) => dispatch => {
   const params = {
        id_order:id
        };
    return apiClient().post(`/getcommandebyid`, params)
        .then((res) => {
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data})
            dispatch(getRestaurentById(res.data.order?.establishment_id))
          
        })
}
export const getRestaurentById = (id) => dispatch => {
    return apiClient().get(`/products/${id}`)
        .then((res) => {
            console.log("res", res.data)
            dispatch({type:SET_CURRENT_RESTAURENT,payload:res.data})
        })
}

export const getListWatcher = (params) => dispatch => {
    const data = {
        ...params,
        
    }
    return apiClient().post(`/getListWatcher`, data)
        .then((res) => {
              dispatch({type:SET_LIST_WATCHERS,payload:res.data?.data})
              window.location.href="/find-a-watcher/"
        })
}


