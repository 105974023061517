import React,{useEffect} from "react";
import './styles.scss';
import { Route, Redirect,useHistory } from 'react-router-dom';
import { useSelector } from "react-redux"
import { Footer, Header } from "..";
const Component = ({ component: Component, protect, parts, ...rest }) => {
    const { user } = useSelector(state => state.user)


    const RenderComponent = (props) => {
        return (
            <div>

                <div className="loader-wrap">
                    <div className="loader-inner">
                        <div className="loader-inner-cirle"></div>
                    </div>
                </div>
                <div id="main">
                  {parts && <Header />}
                    <Component {...props} />
                  {parts && <Footer />}

                </div>
            </div>
        )
    }

    return (
        <Route
            {...rest}
            render={props => (
                protect ?
                    user?.id ?
                        <RenderComponent {...props} />
                        :
                        <Redirect to="/login" />
                    :
                    <RenderComponent {...props} />
            )} />
    );
}

export default Component;