import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,AssistantItem } from "../../../components";
import { useTranslation } from 'react-i18next';
import { loginUser } from "../../../actions/user";

const Page = () => {
   const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const history = useHistory()
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

     useEffect(() => {
       
       
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 


    }, [])


  
    const handleSubmit = (e) => {
       
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())
         dispatch(loginUser(formDataObj,'watcher',currentlang));
    }
       
        
  

    const handleSubmit2 = (e) => {
        e.preventDefault();
        window.location.href='/become-a-watcher/'
        
    }
 
    
 
    return (
        <div className="devenirassistant login" >
             
            


            <section className="pd889 mg889" id="sec1"><div className="container big-container">
              <div className="section-title nopadd">
                <h2><span>{t('Connexion')}</span></h2>
                <span className="section-separator"></span>
                
                </div>


                <div className='row'>

                      <div className='col-md-2'></div>
                      <div className='col-md-8'>

            <div className="text-center mx-auto my-5 col-lg-12">
             <form className="mb-5" onSubmit={handleSubmit}>
                
   
 
 

            <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Email')}*</label>
                 <input placeholder="mail@domaine.com" name="email" required type="email" className="form-control"/>
              </div>
            </div>

             <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Mot de passe')}*</label>
                 <input placeholder="********" name="password" type="password" required className="form-control"/>
              </div>
            </div>

 

           
             <Button
                                className="w-100 color-btn-bg upcase " variant="success" type="submit">
                                {t('Connexion')}<i class="fal fa-user"></i>
              </Button>
             <div className="clearfix"></div>
             <br/>
             <div>
                <div className="filter-tags"><input id="check-a3" type="checkbox" name="check"/><label for="check-a3">{t('Se souvenir de moi')}</label></div>
                <div className="lost_password" style={{margin:'0px'}}><a href="/password/watcher">{t('Mot de passe oublié ?')}</a></div>
             </div>
            

        </form>
    </div>

    <div className="log-separator fl-wrap center"><span>{t('OU')}</span></div>
     
    <h5 className='center idn88'>{t("Vous n'avez pas encore de compte assistant !")}</h5>
    
    <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
            <button className="btn color2-bg url_btn float-btn bggreen99" onClick={handleSubmit2}  >{t('Créer un compte')}<i class="fal fa-bookmark"></i></button>

        </div>
        <div className='col-md-3'></div>
    </div>
  



                      </div>
                       <div className='col-md-2'></div>


                      <div className='col-md-12'>
                        
                      </div>


                </div>


     

             </div>

             

         


            </section>
             
        </div>
    );

}

export default Page;