import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import {getContentPage } from "../../actions/pages";

const Page = () => {
    const { slug,slug2 } = useParams();
    
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
    const [blogs, setBlogs] = useState({});
    const { contents,partenaires } = useSelector(state => state.pages)
    const handleClose = () => setShow(false);

   
 
    const handleSubmit = (item) => {

        if(currentlang=='fr'){ 
          window.location.href="/blog/"+slug+"/"+item.slug_fr+"/"+item.id
        }
        else{
           window.location.href="/blog/"+slug+"/"+item.slug_en+"/"+item.id
        }

    }

   
   

    useEffect(() => {
       
 
        if(langset == false){

             const params={
               slug:slug,
               id:slug2,
               page:'blogdetail'
              }


    return apiClient().post(`/getPageText`, params)
            .then((res) => {
                 
                        setBlogs(res.data?.data)
                         
            })


            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])

    return (
        <div className="contact pagecontent" >


           <section className="parallax-section single-par" data-scrollax-parent="true">
                        <div className="bg par-elem " data-bg="images/contact-investisseurs-header.png" data-scrollax="properties: { translateY: '30%' }" style={{backgroundImage: "url(images/contact-investisseurs-header.png)"}}></div>
                        <div className="overlay op7"></div>
                        <div className="container">
                            <div className="section-title center-align big-title">
                                <h2>

                                 {currentlang=='fr' ? blogs.title_fr : blogs.title_en }

                                </h2>
                                <span className="section-separator"></span>
                                 
                            </div>
                        </div>
                        <div className="header-sec-link">
                            <a href="javascript:void(0)" className="custom-scroll-link"> </a> 
                        </div>
           </section>


           <section id="sec1" data-scrollax-parent="true" style={{paddingBottom:"0"}}>
                        <div className="container">
       
                           
                            
                            <div className="about-wrap">
                                <div className="row">

                                <div className='col-md-12'>

                                  {
                                            blogs.id &&
                                            <p className="default-desc margright " dangerouslySetInnerHTML={{__html: currentlang=='fr' ? blogs.description_fr : blogs.description_en }}></p>
                                  }


                                </div>

 
 
                                    

                                     
                                </div>
                            </div>
                            
                          
                        </div>
                    </section>


              
 





           

 
             
        </div>
    );
}

export default Page;