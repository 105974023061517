import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import {getContentPage,getPartenaires } from "../../actions/pages";

const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
    const { contents,partenaires } = useSelector(state => state.pages)

   const handleClose = () => setShow(false);

   
 
    const handleSubmit = (e) => {
        e.preventDefault();
        setShow(true)
    }

     const handleGoTo = (e) => {
        e.preventDefault();
         
    }
   

    useEffect(() => {
         dispatch(getContentPage({},'Qui sommes nous'));
         dispatch(getPartenaires());
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])

    return (
        <div className="contact pagecontent" >


           <section className="parallax-section single-par" data-scrollax-parent="true">
                        <div className="bg par-elem " data-bg="images/960594F1-409A-455A-94C5-DECC65C71D06.png" data-scrollax="properties: { translateY: '30%' }" style={{backgroundImage: "url(images/960594F1-409A-455A-94C5-DECC65C71D06.png)"}}></div>
                        <div className="overlay op7"></div>
                        <div className="container">
                            <div className="section-title center-align big-title">
                                <h2><span>{t('Qui sommes-nous?')}</span></h2>
                                <span className="section-separator"></span>
                                 
                            </div>
                        </div>
                        <div className="header-sec-link">
                            <a href="javascript:void(0)" className="custom-scroll-link"> </a> 
                        </div>
           </section>


           <section id="sec1" data-scrollax-parent="true" style={{paddingBottom:"0"}}>
                        <div className="container">
                            {
                                 contents.titre &&

                                  <div className="section-title">

                                <h2> {currentlang=='fr' ? contents.titre.fr : contents.titre.en }</h2>
                                <div className="section-subtitle">{currentlang=='fr' ? contents.titre.fr : contents.titre.en }</div>
                                <span className="section-separator"></span>
                                
                            </div>
                            }
                           
                            
                            <div className="about-wrap">
                                <div className="row">

                                    <div className="col-md-7 center">
                                        <div className="ab_text">

                                        {
                                            contents.contenu &&
                                            <p className="default-desc margright mrgtop" dangerouslySetInnerHTML={{__html: currentlang=='fr' ? contents.contenu.fr : contents.contenu.en }}></p>
                                        }
                                        
                                            
                                           
                                             
                                        </div>
                                    </div>

                                    <div className="col-md-5 center imgcontent">
                                      <img src="/aboutimg1.png" />
                                   </div>
                                </div>
                            </div>
                            
                            <span className="fw-separator"></span>
                            <div className=" single-facts bold-facts fl-wrap" style={{display:'none'}}>
                               
                                <div className="inline-facts-wrap">
                                    <div className="inline-facts">
                                        <div className="milestone-counter">
                                            <div className="stats animaper">
                                                <div className="num" data-content="0" data-num="1254">1254</div>
                                            </div>
                                        </div>
                                        <h6>New Visiters Every Week</h6>
                                    </div>
                                </div>
                                
                                <div className="inline-facts-wrap">
                                    <div className="inline-facts">
                                        <div className="milestone-counter">
                                            <div className="stats animaper">
                                                <div className="num" data-content="0" data-num="12168">12168</div>
                                            </div>
                                        </div>
                                        <h6>Happy customers every year</h6>
                                    </div>
                                </div>
                                
                                <div className="inline-facts-wrap">
                                    <div className="inline-facts">
                                        <div className="milestone-counter">
                                            <div className="stats animaper">
                                                <div className="num" data-content="0" data-num="2172">2172</div>
                                            </div>
                                        </div>
                                        <h6>Won Awards</h6>
                                    </div>
                                </div>
                           
                                <div className="inline-facts-wrap">
                                    <div className="inline-facts">
                                        <div className="milestone-counter">
                                            <div className="stats animaper">
                                                <div className="num" data-content="0" data-num="732">732</div>
                                            </div>
                                        </div>
                                        <h6>New Listing Every Week</h6>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>


              



                    <div className="waveWrapper waveAnimation">
                          <div className="waveWrapperInner bgMiddle">
                            <div className="wave-bg-anim waveMiddle" style={{backgroundImage: "url('/images/wave-top.png')"}}></div>
                          </div>
                          <div className="waveWrapperInner bgBottom">
                            <div className="wave-bg-anim waveBottom" style={{backgroundImage: "url('/images/wave-top.png')"}}></div>
                          </div>
                    </div>


                    <section className="gray-bg">
                        <div className="container">

                        <div className="section-title">
                                <h2> {t('Nos partenaires')}</h2>
                               
                                <span className="section-separator"></span>
                                <p>{t('Nos_partenaires_desc')}</p>
                            </div>

                            <div className="clients-carousel-wrap fl-wrap">
                                <div className="cc-btn   cc-prev center" tabindex="0" role="button" aria-label="Previous slide"><i className="fal fa-angle-left"></i></div>
                                <div className="cc-btn cc-next center" tabindex="0" role="button" aria-label="Next slide"><i className="fal fa-angle-right"></i></div>
                                <div className="clients-carousel">
                                    <div className="swiper-container swiper-container-horizontal"  >
                                        <div className="swiper-wrapper"  >


                                    {partenaires.map((item,index) => {
                                      
                                       return (
                                            <div key={index} className="swiper-slide" data-swiper-slide-index="1"  >
                                                <a href="javascript:void(0)" className="client-item" title={item.titre}><img src={item.image} alt=""/></a>
                                            </div>
                                        );
                            
                                     })}

                                           
                                 

                                        </div>

                                     </div>
                                                                                                                                                                                                                                          
                                        
                                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                </div>
                            </div>
                        </div>
                    </section>





           

 
             
        </div>
    );
}

export default Page;