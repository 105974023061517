import React,{useEffect} from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { loginUser } from "../../actions/user";

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const history = useHistory()

    useEffect(() => {
        if (user.id) {
            history.replace("/")
        }
    }, [user.id])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        console.log("formDataObj", formDataObj);
        dispatch(loginUser(formDataObj));
    }
    return (
        <div className="splashscreen" >
            <Container>
                <Row>
                    <Col lg={{ span: 5 }} className="text-center mx-auto my-5" >
                        <img src={logo} alt="logo" className="logo mb-5" />
                        <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 >Connexion</h3>
                            <Form.Group className="mb-3" >
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="mail@website.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe*</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Min. 8 character" />
                            </Form.Group>
                            {/* <Form.Group className="d-flex">
                                <Form.Check type="checkbox" label="Remember me" />
                                <Link className="forgetlink" to="/password">Forgot your password?</Link>
                            </Form.Group> */}
                            <Button
                                className="w-100" variant="primary" type="submit">
                                Connexion
                            </Button>
                        </Form>
                        <p className="mb-1" >Vous n'avez pas de compte ?</p>
                        <Link to="/register">Inscription</Link>
                        <p className="rights" >© {new Date().getFullYear()} AllezHop. All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;