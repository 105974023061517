import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,AssistantItem,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import { getListWatcherBymission } from "../../../actions/pages";

const Page = () => {

    const dispatch = useDispatch();
    const { slug } = useParams()
    const { user } = useSelector(state => state.user)
    const { watchersbymission } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
        e.preventDefault();
        
      
    }


    useEffect(() => {
        const params={
           id:user.id,
           id_mission:slug,
           token:user.emailToken
        }

        dispatch(getListWatcherBymission(params));
        
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
    
 
    return (
        <div className="myccount responses " >
           <DashbordHeader src={'u'} />

            <section className="gray-bg main-dashboard-sec" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord />
                                 
                            </div>


                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div class="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{watchersbymission.length} {watchersbymission.length> 1 ? t('Watchers disponibles') : t('Watcher disponible')}   </h3>
                                    <br/><br/>


                                    {watchersbymission.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                             <AssistantItem key={index} ln={currentlang} item={item} src={'d'} />
                                        );
                            
                                     })}

                                    
                                     



                                
                                 </div>
                              </div>
 

                              

                         </div>
                    </div>
            </section>


        </div>
    );
}

export default Page;