import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import { getWatcher,saveMission } from "../../actions/pages";
import Slider from '@material-ui/core/Slider';


const Page = () => {
    const { slug,slug1 } = useParams();

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const {watcher } = useSelector(state => state.pages)
    const { user } = useSelector(state => state.user)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow(false);

    const [price, setPrice] =  useState(10);
    const [minprice, setMinprice] =  useState(10);
    const [daterun, setDaterun] =  useState(0);

    const categoryitem = localStorage.getItem("categoryitem");
    const villeitem = localStorage.getItem("villeitem");

 // Changing State when volume increases/decreases
    const rangeSelector = (event, newValue) => {
      setPrice(newValue);
      
    };

    const findAwatcher = () =>{
        window.location.href='/dashboard/watchers/'+slug1;
    }
 
    const handleSubmit = (e) => {
        e.preventDefault();
       
         let go=true;

         if(!categoryitem || !villeitem ){
            go=false;
            window.location.href='/';
          }

          if(price < minprice ){
              alert(t('Le prix minimum imposé est : ')+minprice+'€');
              return false;
          }




          if(user.id){
            const formData = new FormData(e.target)
            const formDataObj = Object.fromEntries(formData.entries())
            dispatch(saveMission(formDataObj,price,categoryitem,villeitem,user.id,user.emailToken,currentlang));
            setShow(false)
            setShow2(true)
          }
          else{
              alert(t('Veuillez vous connecter à votre espace client !'));
            
          }
    }

 const handleGoTo = (e) => {
        e.preventDefault();
        window.location.href='/mon-compte'
    }

    const handleShow = (e) => {
        e.preventDefault();

        setShow(true)
        setDaterun(1)


    }


    useEffect(() => {
         const params={
            id:slug
         }
        dispatch(getWatcher(params))
        if(langset == false){

             apiClient().get(`/get/price`,{})
            .then((res) => {
               if(res.data.success==true){
                
                 setPrice(Number(res.data.data))
                 setMinprice(Number(res.data.data)) 
               }
                      
            })

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
 

    }, [])


    const returnReviews= (note) => {
          
         return(

        
            note==1 ?
             <div><i class="fas fa-star"></i></div>
             : note == 2 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 3 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 4 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 5 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             :
             <div></div>

          
       )
    }
   
    return (
        <div className="single" >
             
              <section className="listing-hero-section hidden-section" data-scrollax-parent="true" id="sec1">
                        <div className="bg-parallax-wrap">
                            <div className="ps998 " >


                                  
                                <div className='center p33'>
                                        <div className='ps889' style={{backgroundImage:`url(${watcher.photo })`}}>

                                        </div>
                                </div>
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="container">
                            <div className="list-single-header-item  fl-wrap">
                                <div className="list-single-stats frmb34 return">
                                      <div onClick={findAwatcher} className="geodir_status_date gsd_open selected">{t('Retour')}</div>
                                    </div>
                                <div className="row">
                                    <div className="col-md-9">
                                   

                                        <h1 style={{textTransform:'capitalize'}}> {watcher.prenom} {watcher.lname}. {watcher.intern==1 && <span className=""><img src='/images/staff.png' /></span>} </h1>
                                        <div className="geodir-category-location fl-wrap"><a ><i className="fas fa-map-marker-alt"></i> {watcher.ville}</a>  </div>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="fl-wrap list-single-header-column custom-scroll-link " >
                                            <div className="listing-rating-count-wrap single-list-count">
                                                <div className="review-score">{ parseFloat(watcher.reviews).toFixed(1)}</div>
                                                

                                                <div className="listing-rating card-popup-rainingvis" data-starrating2="4">
                                                {returnReviews(watcher.reviews)}
                                                <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div>

                                                </div>
                                                

                                                <br/>                                                
                                                <div className="reviews-count">{watcher.avis} {t('avis')}</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="list-single-header_bottom fl-wrap">
                       {
                                watcher.catgories &&
                          
                            <div>

                                 {watcher.catgories.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                        
                                            
                                                 <a key={index} className={"listing-item-category-wrap mrg88"} >
                                                    <div className={"listing-item-category  center tain"+newindex}><i className="far fa-check"></i></div>
                                                    <span>{currentlang == 'fr' ? item.label : item.label_en }</span>
                                                </a>
                                             

                                        );
                            
                                   })}


                            </div>
                              }
 
                                
                                <div className="geodir_status_date gsd_open price frmb34">€{watcher.tarif}/hr</div>
                                <div className="geodir_status_date gsd_close missions frmb34">{watcher.nbmission} mission{watcher.nbmission > 1 && "s"}</div> 
                                <div className="list-single-stats frmb34">
                                 
                                </div>
                            </div>
                        </div>
                    </section>



                    <section className="gray-bg no-top-padding">
                        <div className="container">
                            
                            <div className="clearfix"></div>
                            <div className="row">
                                
                                <div className="col-md-12">
                                    
                                    <div className="list-single-main-wrapper fl-wrap" id="sec2">
                                        
                                         
                                        <div className="list-single-main-item fl-wrap block_box">
                                            <div className="list-single-main-item-title">
                                                <h3>Description</h3>
                                            </div>
                                            <div className="list-single-main-item_content fl-wrap">
                                                 {watcher.description}
                                            </div>
                                        </div>

                                        {
                                            /*


                                                <div className="list-single-main-item fl-wrap block_box">
                                            <div className="list-single-main-item-title">
                                                <h3>Tags</h3>
                                            </div>
                                            <div className="list-single-main-item_content fl-wrap">
                                                <div className="listing-features fl-wrap">
                                                    <ul className="no-list-style tags89">
                                                        <li><a  > Elevator in building</a></li>
                                                        <li><a  > Free Wi Fi</a></li>
                                                        <li><a  > Free Parking</a></li>
                                                        <li><a  > Air Conditioned</a></li>
                                                        <li><a  > Online Ordering</a></li>
                                                        <li><a  > Pet Friendly</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>




                                            */
                                        }
                                                                                       
                                         
                                      
                                        <div className="list-single-main-item fl-wrap block_box" id="sec5">
                                            <div className="list-single-main-item-title">
                                                <h3>{t('avis')} -  <span> {watcher.avis} </span></h3>
                                            </div>
                                               
                                            
                                                                                         
                                            <div className="list-single-main-item_content fl-wrap">
                                                <div className="reviews-comments-wrap">

                                    {watcher.evaluations && watcher.evaluations.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                        
                                                 <div key={newindex} className="reviews-comments-item">
                                                        <div className="review-comments-avatar">
                                                           
                                                            <div className='ps9988' style={{backgroundImage:`url(${item.profil_picture })`}}>

                                                              </div> 
                                                        </div>
                                                        <div className="reviews-comments-item-text fl-wrap">
                                                            <div className="reviews-comments-header fl-wrap">
                                                                <h4><a href="javascript:void(0)" >{item.prenom} {item.nom}</a></h4>
                                                                <div className="review-score-user">
                                                                    <span className="review-score-user_item center">{item.note}</span>
                                                                    <div className="listing-rating card-popup-rainingvis" data-starrating2="4">
                                                {returnReviews(item.note)}
                               
                                                </div>
                                                                </div>
                                                            </div>
                                                            <p>{item.commentaire}</p>
                                                            <div className="reviews-comments-item-footer fl-wrap">
                                                                <div className="reviews-comments-item-date"><span><i className="far fa-calendar-check"></i>{item.date}</span></div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                             

                                        );
                            
                                   })}

                                                     
                                                
                                                    
                                            
                                                                                                                     
                                                </div>
                                            </div>
                                        </div>
                                                                               
                                           
                                        
                                                                            
                                    </div>
                                </div>
                                                              
                            </div>
                        </div>
                    </section>

          
        </div>
    );
}

export default Page;