import React from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";


const Component = ({ item,lang }) => {
    const history = useHistory();

   

    return (
            <div className="swiper-slide avis-item" key={item.id}>
                                            <div className="testi-item fl-wrap center">
                                                <div className="testi-avatar"><img src={item.image1} alt="" /></div>
                                                <div className="testimonilas-text fl-wrap">
                                                    <div className="listing-rating card-popup-rainingvis">
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                    </div>
                                                    <p className='avislanfren'>"   {lang == 'fr' ? item.description : item.description_en}"</p>
                                                    <p className='thisavisfr'>"   {item.description }"</p>
                                                    <p className='thisavisen'>"   {item.description_en }"</p>
                                                     
                                                    <div className="testimonilas-avatar fl-wrap">
                                                        <h3>{item.name}</h3>
                                                        
                                                    </div>
                                                </div>
                                            </div>
            </div>
    );
}

export default Component;