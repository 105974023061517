import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { getMissionById,EditMission,getDetailMission,setFiles } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"

const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { slug } = useParams()
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { detailmission,files } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);
    const [price, setPrice] = useState(false);
    const [newprice, setNewPrice] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
 
         e.preventDefault();
        const params={
            newprice:newprice ? newprice : detailmission.price
        }
        dispatch(EditMission(params,user.id,slug,user.emailToken,'editbywatcher','watcher'));
        setShow(true)
        
      
    }

     const handlePrice = async (e) => {
         setPrice(!price)
        
      
    }


    const createFile = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'videoimg');
      data.append('id_mission', slug);

      apiClient().post(`/insertimage`,data)
            .then((res) => {
              const params={
                id_mission:slug
              }
              dispatch(setFiles(params,user.id,'',user.type==2 ? 'watcher' : 'user','select',user.emailToken,''));
               setLoading(false)             
            })

  }

    const uploadFIle = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='video/mp4' || files[0].type=='video/quicktime' || files[0].type=='video/mov' ||files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' ){
              if(files[0].size <= (10*1000000)){
                 createFile(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 10 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PNG,JPEG,JPEG,GIF,MOV,MP4)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }


    useEffect(() => {
          const params={
           id:user.id,
           token:user.emailToken,
           id_mission:slug,
           type:'watcher'
        }

        dispatch(getDetailMission(params));

         const params2={
                id_mission:slug
              }

        dispatch(setFiles(params2,user.id,'',user.type==2 ? 'watcher' : 'user','select',user.emailToken,''));
    
        
        if(langset == false){
             

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
    
 
    return (
        <div className="myccount responses " >
            
            <DashbordHeader />

            <section className="gray-bg main-dashboard-sec dashboard detailmission" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='profil' />
                                 
                            </div>


                            <div className='col-md-9'>

                                <div className='tab' id=''>
                                 <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Détail Mission')} Ref #{detailmission.ref} </h3>
                                    <br/><br/>

                                    <div className="user-profile-header fl-wrap">
                                            <div className="user-profile-header_media fl-wrap">
                                                <div className="bg bg-parallax-wrap" ></div>
                                                <div className="user-profile-header_media_title">
                                                     {
                                                        detailmission.user && 
                                                           <h4>{detailmission.user.prenom} {detailmission.user.nom}</h4>
                                                     }
                                                   
                                                </div>
                                                <div className="user-profile-header_stats">
                                                     
                                                </div>
                                                {
                                                detailmission.statwatcher == 1 &&
                                                <div className="follow-btn color2-bg" onClick={handleSubmit} >{t('Accepter la mission')} <i className="fal fa-check"></i></div>
                                                }
                                            </div>
                                            <div className="user-profile-header_content fl-wrap">
                                                <div className="user-profile-header-avatar">
                                                    <img src={detailmission.user && detailmission.user.photo} alt="" />
                                                </div>
                                                <p style={{    fontSize: '19px',color:'#000',textAlign:'center'}}> {detailmission.description}   </p>
                                                

                                                <div className="list-author-widget-contacts list-item-widget-contacts bwc-padside">

                                                     <div className='row'>

                                                       <div className='col-md-6'>

                                                           <ul className="no-list-style">
                                                            <li><span><i className="fal fa-map-marker"></i> {t('Ville')}:</span> <a href="javascript:void(0)">{detailmission.ville}</a></li>
                                                            <li><span><i className="fal fa-check"></i> {t('Catégorie')}:</span> <a href="javascript:void(0)">{currentlang=='fr' ?  detailmission.label : detailmission.label_en }</a></li>
                                                            <li><span><i className="fal fal fa-calendar"></i> {t('Durée approximative')}:</span> <a href="javascript:void(0)">{detailmission.duree}</a></li>
 
                                                        </ul>

                                                       </div>

                                                       <div className='col-md-6'>


                                                        <ul className="no-list-style">
                                                            <li><span><i className="fal fa-map-marker"></i> {t('Adresse')}:</span> <a href="javascript:void(0)">{detailmission.adresse}</a></li>
                                                            <li><span><i className="fal fa-calendar"></i> {t('Date souhaitée')}:</span> <a href="javascript:void(0)">{detailmission.date_mission}</a></li>

                                                            <li><span><i className="fas fa-euro-sign"></i> {t('Prix proposé')}:</span> <a href="javascript:void(0)">€{detailmission.price}</a></li>
                                                               {
                                                              detailmission.watcher &&
 <li><span><i className="fas fa-euro-sign"></i> {t('Prix accepté')}:</span> <a href="javascript:void(0)">€{detailmission.pricewatcher}</a></li>
                                                                 
                                                             }
                                                        </ul>

                                                          <div>

                                                          </div>

                                                         


                                                       </div>
 
                                                     </div>
                                                     <br/>



                                                     <div className='row'>
                                                       <div className='col-md-6'>
                                                       {
                                                        detailmission.statwatcher==1 &&
                                                           <Form.Group className="mb-4">
                                                               <Form.Check onClick={()=>handlePrice()} type="checkbox" label={t('Je veux modifier le prix proposé !')}   />
                                                            </Form.Group> 
                                                       }
                                                       
                                                       </div>
                                                        <div className='col-md-6'>

                                                        {
                                                            price && <Form.Control    type="number" min={detailmission.price} className="form-control"  value={newprice} onChange={e => setNewPrice(e.target.value)} placeholder={t('Votre prix..')} />
                                                        }
 
                                                         
 
                                                       </div>


                                                     </div>

                                                       
                                                </div>

                                                <br/>

                        
  

                                              {
                                                detailmission.statwatcher == 1 &&
                                                <div className="follow-btn color2-bg btn9987"  onClick={handleSubmit}>{t('Accepter la mission')} <i className="fal fa-check"></i></div>
                                              }


                                              {
                                                (detailmission.statwatcher == 3 || detailmission.statwatcher == 4) &&

                                                <div className=''>

                                      <div className="custom-form">
                                        <div className="row">

                                          <div className='col-md-12'>

                                              <p className='para alert alert-warning center'>{t('Uploader un ou plusieurs fichiers pour faire valider votre mission')}.</p>

                                          </div>
                                        </div>
                                    </div>              

                                    <div className="custom-form">
                                        <div className="row">

                                          <div className='col-md-6'>

                                            <label  for="imgprofil" className="color-bg edit-prof_btn labbnrd">

                                              <input
                                                type="file"
                                                name="imgprofil"
                                                className='inputFileInvis'
                                                onChange={(event) => {
                                                  
                                                  uploadFIle(event);
                                                }}
                                              />
                                              {
                                                 
                                                t('Uploader un fichier (photo, vidéo..)')
                                              }

                                                

                                            </label>
                                          </div>
                                          <div className='col-md-6'>

                                          <ul>


                                            {files.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            <li  key={index} className=''><a href={item.url} download className="">{item.file}</a></li>
                                        );
                            
                                     })}

                                          </ul>


                                          

                                    

                                          

                                          { 
                                            loading &&  <img src='/images/loading.gif' />
                                          }

                                          { 
                                            loading &&  <p className='alert alert-primary'>
                                               {t('Cette operation peut durer plusieurs minutes, merci de ne pas fermer cette fenêtre..')}
                                            </p>
                                          }
 
                                           
                                          </div>

                                        </div>
                                    </div>
 

                                                </div>
                                                
                                              }



                                               
                                               {
                                                /*

                                                 <div className="follow-btn red-bg btn9987 btn776">Contacter le client <i className="fal fa-envelope"></i></div>
 
                                                */
                                               }
                                               

                                            </div>



                                        </div>






                                </div>
                             </div>

                             


                                 
                             </div>


                              

                    </div>
                    
            </section>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
            <img src='/images/validation-150x150.png' />
          </div>

          <br/><br/>
            <div className='center'><h4 className='center'>{t('Félicitations')} </h4></div>
           <br/>
          <div className='center'>
             {t('Votre réponse a bien été enregistrée..')}
          </div>

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>
        </div>
    );
}

export default Page;