import React,{ useState, useEffect } from "react";
import './styles.scss';
import { Container, Navbar, Nav } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { logo,addtocart } from "../../assets/images";
import { LOGOUT_USER } from "../../constants/actions";
import { BiUser,BiLogOut} from 'react-icons/bi';
import { CURRENCY } from "../../components/config/keys";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../actions/api"

const Component = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data:basket,total } = useSelector(state => state.basket)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [telephone, setTelephone] = useState('');

    useEffect(() => {

         apiClient().get(`/get/tel`,{})
            .then((res) => {
               if(res.data.success==true){
               setTelephone(res.data.data)
                 
               }
                      
            })
        
        if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
        }
        else{
             i18n.changeLanguage(localStorage.getItem("currentlang"))
        }
    }, []);



    const handleLogout = () =>{
        dispatch({type:LOGOUT_USER});
    }

    const findAwatcher = () =>{
        window.location.href='/find-a-watcher/';
    }
    const handleLangChange = (ln) =>{
         if(ln=='fr'){
           i18n.changeLanguage('fr')
           localStorage.setItem("currentlang",'fr')
         }
         else{
          i18n.changeLanguage('en')
          localStorage.setItem("currentlang",'en')
         }
    }

    return (
            <header className="main-header">

                <div className="side-function-wrap">
                   <ul className="side-function">
                     <li className="side-help-center">
                        <a href="/contact/" className='show-help-center '   rel="nofollow"><img className='hotline' src='/images/callmywatcher.png' /><div className='hotlinenumber'>{telephone}</div></a>
            
                        </li>
                     </ul>
                </div>
                
                <a href="/" className="logo-holder"><img src="/images/newlogo.png" alt=""/></a>
                 
                        
                <div className="header-search_btn " onClick={findAwatcher}><i className='fal fa-check'></i><span>{t('Choisir un assistant')}</span></div>
               
                 <div className="lang-wrap">
                    <div className="show-lang"><span><i className="fal fa-globe-europe"></i><strong>{currentlang=='en' ? "EN" : "FR"}</strong></span><i className="fa fa-caret-down arrlan"></i></div>
                    <ul className="lang-tooltip lang-action no-list-style">
                        <li><a href="javascript:void(0)" onClick={()=>handleLangChange('en')} className={currentlang=='en' ? "current-lan" : ""} data-lantext="En">English</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>handleLangChange('fr')} className={currentlang=='fr' ? "current-lan" : ""} data-lantext="Fr">Français</a></li>
                    </ul>
                </div>
                
               {user.id ?

                 <a href={user.type==1 ? '/my-account/' : '/watchers/dashboard/'  } className=" avatar-img show-reg-form nav-link-resiter" style={{    textTransform: 'capitalize'}} ><i className="fal fa-user"></i>{user.prenom}</a>
                :
                 <div className="show-reg-form show-reg-form-hover nav-link-resiter " data-srcav="/images/avatar/3.jpg">
                     <i className="fal fa-user"></i>{t('Connexion')}

                     <div className='show-reg-content'>

                        <a class=" color2-bg modal-open avatar-img "> {t('Connexion client')}  <i class="fas fa-caret-right"></i></a>


                        <a class=" color2-bg" href='/watchers/login'> {t('Connexion assistant')}  <i class="fas fa-caret-right"></i></a>
                

                     </div>



                 </div>

               }
 
         
                
                <div className="nav-button-wrap color-bg">
                    <div className="nav-button">
                        <span></span><span></span><span></span>
                    </div>
                </div>
                
                <div className="nav-holder main-menu">
                    <nav>
                        <ul className="no-list-style">
                            <li>
                                <a href="/">{t('Accueil')}</a>
                            </li>

                            <li className='finwatcherlink'>
                                <a href="/">{t('Choisir un assistant')}</a>
                            </li>  
                             {
                                currentlang =='en' &&
                                 <li><a href="/about-us">{t('Qui sommes nous')}</a></li>

                            }
                             <li>
                                <a href="/how-it-works">{t('Comment ça marche')}</a>
                            </li>
                             <li>
                                <a href="/become-a-watcher">{t('Devenir assistant')}</a>
                            </li>
                            
                            
                            
                          <li>
                       
                            <a href="/watchers/login" className="" style={{display:'none'}}>{t('Connexion assistant')}</a>
                       
                           </li>

                        </ul>
                    </nav>
                </div>
                                     
  

                 
            </header>
    );
}

export default Component;