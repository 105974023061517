import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import {getContentPage } from "../../actions/pages";
import { Link } from "react-router-dom";

const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
    const { contents } = useSelector(state => state.pages)
    const [telephone, setTelephone] = useState('');
      const [email, setEmail] = useState('');
     const [emailtext, setEmailtext] = useState('');

   const handleClose = () => setShow(false);

   
 
    const handleSubmit = (e) => {
        e.preventDefault();
        setShow(true)
    }

     const handleGoTo = (e) => {
        e.preventDefault();
         
    }
   

    useEffect(() => {
        dispatch(getContentPage({},'TEAM'));
        if(langset == false){
              apiClient().get(`/get/tel`,{})
            .then((res) => {
               if(res.data.success==true){
               setTelephone(res.data.data)
                 
               }
                      
            })


            apiClient().get(`/get/email`,{})
            .then((res) => {
               if(res.data.success==true){
               setEmail('mailto:'+res.data.data)
               setEmailtext(res.data.data)
                 
               }
                      
            })

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])

    return (
        <div className="contact pagecontent" >


           <section className="parallax-section single-par" data-scrollax-parent="true">
                        <div className="bg par-elem " data-bg="images/960594F1-409A-455A-94C5-DECC65C71D06.png" data-scrollax="properties: { translateY: '30%' }" style={{backgroundImage: "url(images/960594F1-409A-455A-94C5-DECC65C71D06.png)"}}></div>
                        <div className="overlay op7"></div>
                        <div className="container">
                            <div className="section-title center-align big-title">
                                <h2><span>{t('Rejoindre la team')}</span></h2>
                                <span className="section-separator"></span>
                                 
                            </div>
                        </div>
                        <div className="header-sec-link">
                            <a href="javascript:void(0)" className="custom-scroll-link"> </a> 
                        </div>
           </section>


           <section id="sec1" data-scrollax-parent="true" style={{paddingBottom:"0"}}>
                        <div className="container">
                            {
                                 contents.titre &&

                                  <div className="section-title">

                                <h2> {currentlang=='fr' ? contents.titre.fr : contents.titre.en }</h2>
                                <div className="section-subtitle">{currentlang=='fr' ? contents.titre.fr : contents.titre.en }</div>
                                <span className="section-separator"></span>
                                
                            </div>
                            }
                           
                            
                            <div className="about-wrap">
                                <div className="row">

                                    <div className="col-md-12 center">
                                        <div className="ab_text">

                                        {
                                            contents.contenu &&
                                            <p className="default-desc margright mrgtop" dangerouslySetInnerHTML={{__html: currentlang=='fr' ? contents.contenu.fr : contents.contenu.en }}></p>
                                        }
                                        
                                            
                                           
                                             
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                            
                            <span className="fw-separator"></span>
                            <div className="post-nav-wrap fl-wrap">
                                                <a className="post-nav post-nav-prev" href="javascript:void(0)"><span className="post-nav-img"><img src="/images/callmywatcher.png" alt=""/></span><span className="post-nav-text"><strong>{t('Appelez-nous 27/7')}</strong> <br/>{telephone}</span></a> 
                                                <a className="post-nav post-nav-next" href="javascript:void(0)"><span className="post-nav-img"><img src="/images/callmywatcher.png" alt=""/></span><span className="post-nav-text"><strong>{t('Écrivez-nous')}</strong><br/><ButtonMailto label={emailtext} mailto={email} /></span></a> 
                    </div>
                            
                        </div>
                    </section>

                    


             
        </div>
    );
}

const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {

                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

export default Page;