import { combineReducers } from 'redux'
import user from './user'
import pages from './pages'
import global from './global'
import basket from './basket'

const rootReducer = combineReducers({
    user,
    pages,
    global,
    basket
})

export default rootReducer;