import React, { useEffect,useState } from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../actions/user";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    
    useEffect(() => {
        if (user.id) {
            history.replace("/")
        }
    }, [user.id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        console.log("formDataObj", formDataObj);
  
        
        if (formDataObj.password === formDataObj.confirmPassword) {
            dispatch(registerUser(formDataObj,startDate));
        } else {
            alert("les deux mots de passe ne correspondent pas")
        }
    }
    return (
        <div className="register" >
            <Container>
                <Row>
                    <Col lg={{ span: 5 }} className="text-center mx-auto my-5" >
                        <img src={logo} alt="logo" className="logo mb-5" />
                        <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 className="mb-4" >Inscription</h3>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Check 
                                        value={1}
                                        type="radio" label="Particulier" name="profile_id" defaultChecked={true} />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Check
                                        value={2} 
                                        type="radio" label="Entreprise" name="profile_id" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Check 
                                        value="M"
                                        type="radio" label="Monsieur" name="gender" defaultChecked={true} />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Check 
                                        value="F"
                                        type="radio" label="Madame" name="gender" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Prénom*</Form.Label>
                                    <Form.Control type="text" placeholder="Robert" name="firstname" />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Nom*</Form.Label>
                                    <Form.Control type="text" placeholder="Aliox" name="lastname" />
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" >
                                <Form.Label>Date naissance*</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                              </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Email*</Form.Label>
                                <Form.Control type="email" placeholder="mail@website.com" name="email" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Téléphone*</Form.Label>
                                <Form.Control type="tel" placeholder="06*****" name="mobile_phone" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe *</Form.Label>
                                <Form.Control type="password" placeholder="Min. 8 character" name="password" />
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Form.Label>Confirmer mot de passe*</Form.Label>
                                <Form.Control type="password" placeholder="Min. 8 character" name="confirmPassword" />
                            </Form.Group>
                            <Button className="w-100" variant="primary" type="submit">
                            S'inscrire
                            </Button>
                        </Form>
                        <p className="mb-1" >Déjà utilisateur ??</p>
                        <Link to="/login">Connexion</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;