//GLOBAL
export const TOGGLE_LOADING ="TOGGLE_LOADING";

//USER
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const SET_USER = "SET_USER"
export const LOGOUT_USER = "LOGOUT_USER"


//PAGES GLOBAL
export const SET_CATEGORIES = "SET_CATEGORIES"
export const SET_REVIEWS = "SET_REVIEWS"
export const SET_LIST_WATCHERS = "SET_LIST_WATCHERS"
export const SAVE_MISSION = "SAVE_MISSION"
export const SET_CONTENT_PAGE = "SET_CONTENT_PAGE"
export const GET_PATENAIRES = "GET_PATENAIRES"
export const GET_FAQ = "GET_FAQ"
export const GET_WATCHERS = "GET_WATCHERS"
export const LIST_MISSIONS = "LIST_MISSIONS"
export const Edit_MISSION = "Edit_MISSION"
export const GET_MISSION = "GET_MISSION"
export const WATCHER_MISSION = "WATCHER_MISSION"
export const GET_DETAIL_MISSION = "GET_DETAIL_MISSION"
export const MISSIONS_VALIDATE = "MISSIONS_VALIDATE"
export const MISSION_ENCOURS = "MISSION_ENCOURS"
export const MISSIONS_REALISE = "MISSIONS_REALISE"
export const GET_WATCHER = "GET_WATCHER"
export const GET_RECLAMATIONS = "GET_RECLAMATIONS"
export const SET_PARAINAGE = "SET_PARAINAGE"
export const GET_AVIS = "GET_AVIS"
export const SET_MESSAGES = "SET_MESSAGES"
export const SET_FILES = "SET_FILES"
export const SET_INVOICES = "SET_INVOICES"
export const SET_RATE = "SET_RATE"




export const SET_CURRENT_RESTAURENT = "SET_CURRENT_RESTAURENT"
export const SET_LIST_ADDRESS = "SET_LIST_ADDRESS"
export const SET_LIST_COMMANDES = "SET_LIST_COMMANDES"
export const SET_CURRENT_COMMANDE = "SET_CURRENT_COMMANDE"
export const SET_HORAIRE_RESTAURENT = "SET_HORAIRE_RESTAURENT"
//BASKET
export const ADD_PRODUCT_BASKET = "ADD_PRODUCT_BASKET"
export const REMOVE_PRODUCT_BASKET = "REMOVE_PRODUCT_BASKET"
export const UPDATE_QTY = "UPDATE_QTY"
export const GET_DATA_CART = "GET_DATA_CART"
export const GET_TOTAL_CART = "GET_TOTAL_CART"
export const OBJECT_REDUCTION = "OBJECT_REDUCTION"
export const VIDER_PANIER = "VIDER_PANIER"

