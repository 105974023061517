import React from "react";
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom"; 
import { CustomRoute } from "../components";
import { 
    Login,
    Splashscreen,
    Register,
    ForgetPassword,
    Home,
    Restaurent,
    Listing,
    Assistantprofil,
    Assistantprofilselect,
    Devenirassistant,
    ConnexionAssistant,
    ProfilAssistant,
    DetailMission,
    DetailProfilMission,
    Profile,
    Responses,
    Paiement,
    EditProfil,
    EditMission,
    EditProfilAssistant,
    MessagesAssistant,
    Messages,
    Factures,
    FacturesAssistant,
    ReclamationsAssistant,
    Reclamations,
    Parainage,
    Contact,
    Commentcamarche,
    Apropos,
    Checkemail,
    Team,
    Talk,
    CheckToken,
    Blog,
    Blogdetail,
    Commentaires,
    CommentairesAssistant,
    Centre,


    Basket,
    Commandes,
    Commande,
    Historique,
    
    Aide,
    Cgv,
    Rgbd,
    Adresses,
    ThermalPrinter
 } from "../pages";

const MainNavigator =()=> {
    return (
        <Router>
            <Switch>
                <CustomRoute exact parts path="/" component={Home} />
                <CustomRoute  parts path="/find-a-watcher" component={Listing} />
                <CustomRoute  parts path="/watcher/:slug" component={Assistantprofil} />
                <CustomRoute  parts path="/account/select/watcher/:slug/:slug1" component={Assistantprofilselect} />
                <CustomRoute  parts path="/become-a-watcher/" component={Devenirassistant} />
                <CustomRoute  parts path="/my-account/" component={Profile} />
                <CustomRoute  parts path="/dashboard/watchers/:slug" component={Responses} />
                <CustomRoute  parts path="/paiement/mission/:slug/:slug1" component={Paiement} />
                <CustomRoute  parts path="/watchers/login" component={ConnexionAssistant} />
                <CustomRoute  parts path="/watchers/dashboard" component={ProfilAssistant} />
                <CustomRoute  parts path="/watchers/detail/mission/:slug" component={DetailMission} />
                <CustomRoute  parts path="/account/detail/mission/:slug" component={DetailProfilMission} />
                <CustomRoute  parts path="/modifier-profile/" component={EditProfil} />
                <CustomRoute  parts path="/edit-mission/:slug" component={EditMission} />
                <CustomRoute  parts path="/assistants/modifier-profile/" component={EditProfilAssistant} />
                <CustomRoute  parts path="/messages" component={Messages} />
                <CustomRoute  parts path="/assistants/messages" component={MessagesAssistant} />
                <CustomRoute  parts path="/factures/" component={Factures} />
                <CustomRoute  parts path="/assistants/factures/" component={FacturesAssistant} />
                <CustomRoute  parts path="/commentaires/" component={Commentaires} />
                <CustomRoute  parts path="/assistants/commentaires/" component={CommentairesAssistant} />
                <CustomRoute  parts path="/assistants/reclamations/" component={ReclamationsAssistant} />
                <CustomRoute  parts path="/reclamations/" component={Reclamations} />
                 <CustomRoute  parts path="/parrainage/" component={Parainage} />
                <CustomRoute  parts path="/contact/" component={Contact} />
                <CustomRoute  parts path="/how-it-works/" component={Commentcamarche} />
                <CustomRoute  parts path="/about-us/" component={Apropos} />
                <CustomRoute  parts path="/centre-reservation/" component={Centre} />
                <CustomRoute  parts path="/talk-about-us" component={Talk} />
                <CustomRoute  parts path="/faq/" component={Aide} />
                <CustomRoute  parts path="/rgbd/" component={Rgbd} />
                <CustomRoute  parts path="/legal-notice/" component={Cgv} />
                <CustomRoute  parts path="/check-your-email/" component={Checkemail} />
                <CustomRoute  parts path="/watchers/token/validate/:slug" component={CheckToken} />
                <CustomRoute  parts path="/join-the-team/" component={Team} />
                <CustomRoute  parts path="/password/:slug" component={ForgetPassword}  />
                <CustomRoute  parts path="/blog/:slug" component={Blog}  />
                <CustomRoute  parts path="/detail/:slug/:slug2" component={Blogdetail}  />
                <CustomRoute parts path="/login" component={Login}  />


                <CustomRoute parts  path="/splashscreen" component={Splashscreen}  />
                <CustomRoute parts path="/register" component={Register}  />
                <CustomRoute  path="/restaurents/:id" component={Restaurent} />
                <CustomRoute  protect path="/basket" component={Basket} />
                <CustomRoute  protect path="/commandes" component={Commandes} />
                <CustomRoute  protect path="/printer" component={ThermalPrinter} />
                
          
            </Switch>
        </Router>
    );
}

export default MainNavigator;