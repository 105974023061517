import React,{useEffect,useState} from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { LOGOUT_USER } from "../../constants/actions";
import { useDispatch, useSelector } from "react-redux"
import { apiClient } from "../../actions/api"


const Component = ({ src }) => {
    const history = useHistory();
     const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const [nbMessage, setNbMessage] = useState(0);


    


    const handleClick = () => {
         window.location.href="/modifier-profile/";
    }


    const handleLogout = () =>{
        dispatch({type:LOGOUT_USER});
    }

 useEffect(() => {
        if (!user || !user.id || user.type!== 1) {
             window.location.href='/'
        }


          const data = {
        
                token:user.emailToken,
                id_user:user.id,
                to:'',
                type_user:'user',
                action:'getnbmessage',
                referer:'none'
        
           }


        apiClient().post(`/setMessages`,data)
            .then((res) => {
                
                    if(res.data?.success){
                        console.log(res.data.data)
                        setNbMessage(res.data?.data)
                         
                    }
            })
        



}, [user])
  

    return (

       <div className='menudashbord'>
             <div className="mob-nav-content-btn color2-bg init-dsmen fl-wrap "><i className="fal fa-bars"></i> Dashboard menu</div>
                                <div className="clearfix"></div>
                                <div className="fixed-bar fl-wrap" id="dash_menu">
                                    <div className="user-profile-menu-wrap fl-wrap block_box">
                                       
                                        <div className="user-profile-menu">
                                            <h3>MENU</h3>
                                            <ul className="no-list-style">
                                                <li><a href="/my-account/" className={src=='profile' && "user-profile-act"}><i className="fal fa-chart-line"></i>{t('Mes demandes')}</a></li>
                                                <li><a href="javascript:void(0)" className={src=='editprofil' && "user-profile-act"} onClick={handleClick}><i className="fal fa-user-edit"></i> {t('Mon profil')}</a></li>
                                                <li><a href="/messages" className={src=='messages' && "user-profile-act"}><i className="fal fa-envelope"></i>{t('Mes messages')} <span>{nbMessage}</span></a></li>
                                                <li><a href="/factures" className={src=='factures' && "user-profile-act"}><i className="fal fa-receipt"></i>{t('Mes factures')}</a></li>
                                                <li><a href="/commentaires/" style={{textTransform: 'capitalize'}}  className={src=='commentaires' && "user-profile-act"}><i className="fal fa-comment"></i>{t('avis')}</a></li>
                                                <li><a href="/parrainage" className={src=='parrainage' && "user-profile-act"}><i className="fal fa-envelope"></i>{t('Parrainage')}</a></li>
                                                <li><a href="/reclamations" className={src=='reclamations' && "user-profile-act"}><i className="fal fa-rss"></i>{t('Laisser une réclamation')}</a></li>
                                                
                                            </ul>
                                            <button  onClick={handleLogout} className="logout_btn color2-bg">{t('Déconnexion')} <i class="fas fa-sign-out"></i></button>
                                        </div>
                                                                        
                                        
                                    </div>
            </div>
        </div>
    );
}

export default Component;