import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { AvisItem } from "../../components";
import { getAllCategories,getAllReviews,getWatchersHome } from "../../actions/pages";
import { getListWatcher } from "../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../components/config/keys";
import { surpls,epr } from "../../assets/images";
import { useTranslation } from 'react-i18next';
import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
 

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const { data,reviews,watchers } = useSelector(state => state.pages)
    const [dataCategories, setDataCategories] = useState(data)
    const [categorie, setCategorie] = useState(102)
    const [address,setAddress]=useState("");
    const [city,setCity]=useState("");
    const [value, setValue] = useState(null);
    const [lat,setLat]=useState("");
    const [lng,setLng]=useState("");

    const popuphowitwoarck = localStorage.getItem("popuphowitwoarck");
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
    const [oneShow, setOneShow] = useState(false);
    const [langset, setLangset] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
       setOneShow(true);
       setShow(true);
       

     };
    const handleClick = () => {
       
         let go=true;

         if(!categorie){
            go=false;
            if(currentlang=='fr'){
                alert("Veuillez sélectionner  une catégorie !")
                 return false;
            }
            else{
                alert("Please select category!")
                 return false;
            } 
         }

         if(!value || address!=value){
            go=false;
            if(currentlang=='fr'){
                alert("Veuillez choisir une ville !")
                return false;
            }
            else{
                alert("Please choose a city!")
                 return false;
            } 
        }

        const params = {
                ville: value,
                categorie: categorie,
        }

        localStorage.setItem("categoryitem",categorie)
        localStorage.setItem("villeitem",value)

        dispatch(getListWatcher(params));

 
    }

    const handleSetCategorie = (categ) => {
          setCategorie(categ);
    }

    const handleCheck = () => {
          
    }

    const handleCreateWatcher = () => {
           window.location.href="/become-a-watcher/";
    }
 

    useEffect(() => {
        dispatch(getAllCategories());
        dispatch(getWatchersHome());
        dispatch(getAllReviews());
        if(langset == false){

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

        
        
        if(oneShow==false && popuphowitwoarck != 'true'){
            if(window.screen.width>900){
                //  setTimeout(handleShow, 3000);
            }
             
              localStorage.setItem("popuphowitwoarck", true)
        } 

    }, [])
 
      const handleChange = address => {
        setAddress(address);
      };
     const selectValuemap=(value)=>{
         
        setAddress(value);
        setValue(value);
        geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{
            setLat(lat);
            setLng(lng);
         }
         );

     }

 const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        
        <div className="main-search-input-item location autocomplete-container autocomplete-root">
                                                   
                <label><i className="fal fa-map-marker-check"></i></label>
                <input  {...getInputProps({
                          placeholder: t('Dans quelle ville souhaiteriez être assisté ?'),
                          className: 'utocomplete-input',
                      })} 
                />
                <a href="javascript:void(0)"><i className="fa fa-dot-circle-o"></i></a>

        <div className="autocomplete-dropdown-container my-2">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => (
            
              <div key={index} className="listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>

      
      );

    return (
        <div id="homwraped" className="home">
                <div className="content">
                    <section className="hero-section"   data-scrollax-parent="true">
                        <div className="bg-tabs-wrap">
                            <div className="bg-parallax-wrap" data-scrollax="properties: { translateY: '200px' }">
                                <div className="video-container">
                                    <video autoPlay  loop muted  className="bgvid">
                                        <source src="https://2checkhotels.com/video/4.mp4" type="video/mp4" />
                                    </video>
                                </div>
                                
                                <div className="bg mob_bg" data-bg="images/bg/1.jpg"></div>
                                <div className="overlay op7"></div>
                            </div>
                        </div>
                        <div className="container small-container center">
                            <div className="intro-item fl-wrap">
                                <span className="section-separator"></span>
                                <div className="bubbles">
                                    <h1 style={{fontSize:'27px',marginTop:'30px',textTransform:'uppercase'}}>{t('Vérifier en live n’importe quel lieu dans le monde')}</h1>
                                </div>
                                 
                                 
                            </div>

                        <div className='row'>
                            <div className='col-md-1'></div>
                            <div className='col-md-10'>

                            <div className="main-search-input-tabs  tabs-act fl-wrap">
                            <h2 className='li778'>{t('Que souhaitez vous Vérifier ?')}</h2>
                                <ul className="tabs-menu  no-list-style">
                                    <li className='li778'> </li>
                                    {data.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            <li  key={index} className={categorie == item.id ? 'current' : ''}><a href="javascript:void(0)" onClick={()=>handleSetCategorie(item.id)} className={'tain'+newindex}>{currentlang == 'fr' ? item.label : item.label_en }</a></li>
                                        );
                            
                                     })}
                                    
                                   
                             
                                </ul>
                                                       
                                <div className="tabs-container fl-wrap  ">
                                    
                                    <div className="tab">
                                        <div id="tab-inpt1" className="tab-content first-tab">
                                            <div className="main-search-input-wrap fl-wrap">
                                                <div className="main-search-input fl-wrap">
                                                    
                                                    
                                                    <PlacesAutocomplete  value={address} onChange={handleChange}   onSelect={selectValuemap} searchOptions={{ types: ['(cities)'] }} >
                                                      {renderFunc}
                                                    </PlacesAutocomplete>
                                                     
                                                    <button className="main-search-button color2-bg" onClick={handleClick}>{t('Vérifier')} <i className="far fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="tab">
                                        <div id="tab-inpt2" className="tab-content">
                                      <div className="main-search-input-wrap fl-wrap">
                                                <div className="main-search-input fl-wrap">
                                                    
                                                    <div className="main-search-input-item location autocomplete-container">
                                                        <label><i className="fal fa-map-marker-check"></i></label>
                                                        <input type="text" placeholder={t('Dans quelle ville souhaiteriez être assisté ?')} className="autocomplete-input" id="autocompleteid" value=""/>
                                                        <a href="javascript:void(0)"><i className="fa fa-dot-circle-o"></i></a>
                                                    </div>
                                                     
                                                    <button className="main-search-button color2-bg" onClick={handleClick}>{t('Vérifier')} <i className="far fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="tab">
                                        <div id="tab-inpt3" className="tab-content">
                                          <div className="main-search-input-wrap fl-wrap">
                                                <div className="main-search-input fl-wrap">
                                                 
                                                    <div className="main-search-input-item location autocomplete-container">
                                                        <label><i className="fal fa-map-marker-check"></i></label>
                                                        <input type="text" placeholder={t('Dans quelle ville souhaiteriez être assisté ?')} className="autocomplete-input" id="autocompleteid" value=""/>
                                                        <a href="javascript:void(0)"><i className="fa fa-dot-circle-o"></i></a>
                                                    </div>
                                                     
                                                    <button className="main-search-button color2-bg" onClick={handleClick}>{t('Vérifier')} <i className="far fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     
                                    <div className="tab">
                                        <div id="tab-inpt4" className="tab-content">
                                         <div className="main-search-input-wrap fl-wrap">
                                                <div className="main-search-input fl-wrap">
                                                    
                                                    <div className="main-search-input-item location autocomplete-container">
                                                        <label><i className="fal fa-map-marker-check"></i></label>
                                                        <input type="text" placeholder={t('Dans quelle ville souhaiteriez être assisté ?')} className="autocomplete-input" id="autocompleteid" value=""/>
                                                        <a href="javascript:void(0)"><i className="fa fa-dot-circle-o"></i></a>
                                                    </div>
                                                     
                                                    <button className="main-search-button color2-bg" onClick={handleClick}>{t('Vérifier')} <i className="far fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                                     
                                </div>
                                 
                            </div>
                        </div>
                <div className='col-md-1'></div>
            </div>
                            
                            <div className="hero-categories fl-wrap">
                                <h4 className="hero-categories_title">{t('home_small_desc_for_watcher')}</h4>
                                <button  onClick={handleCreateWatcher} className="header-assistant-button green-bg">{t('Me proposer comme assistant')} </button>
                            </div>
                        </div>
                        <div className="header-sec-link" onClick={handleCreateWatcher}>
                            <a href="javascript:void(0)" className="custom-scroll-link"><i className="fal fa-angle-double-down"></i></a> 
                        </div>
                    </section>


                    <section className='pd889'>
                        <div className="container big-container">
                            <div className="section-title">
                                <h2><span>{t('Meilleurs assistants')}</span></h2>
                                <span className="section-separator"></span>
                                <p className='center'>{t('Best_watchers_desc')}</p>
                            </div>

                            <div className="grid-item-holder gallery-items fl-wrap">
                               
                                    <div className="listing-item row">

                                     {watchers.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                             <AssistantItem key={index} ln={currentlang} item={item} src={'h'} />
                                        );
                            
                                     })}
                                    

                                    </div>
                                

                            </div>



                        </div>
                    </section>
                    <div className="sec-circle fl-wrap"></div>
                      <section   className="gray-bg hidden-section particles-wrapper">
                        <div className="container">
                            <div className="section-title">
                                <h2>{t('Les bons plans du moment')}</h2>
                                <span className="section-separator"></span>
                                <p className='center'>{t('good_plans_desc')}</p>
                            </div>

                            <div className="listing-item-grid_container fl-wrap">
                                <div className="row">
                                     
                                    <div className="col-sm-4">
                                        <div className="listing-item-grid">
                                            <div className="bg"  data-bg="images/all/hotel-deserto-marrocos.jpg"></div>
                                            <div className="d-gr-sec"></div>
                                           
                                            <div className="listing-item-grid_title">
                                                <h3><a href="/blog/hotels">{t('Hôtels')}</a></h3>
                                                 
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-4">
                                        <div className="listing-item-grid">
                                            <div className="bg"  data-bg="images/all/restaurants-a-marrakech-700x450.jpg"></div>
                                            <div className="d-gr-sec"></div>
                                           
                                            <div className="listing-item-grid_title">
                                                <h3><a href="/blog/restaurants">{t('Restaurants & Bars')}</a></h3>
                                                 
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-4">
                                        <div className="listing-item-grid">
                                            <div className="bg"  data-bg="images/all/espace-optimise-appartement-meuble-paris.jpg"></div>
                                            <div className="d-gr-sec"></div>
                                           
                                            <div className="listing-item-grid_title">
                                                <h3><a href="/blog/appartements">{t('Appartements & villas')}</a></h3>
                                               
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="listing-item-grid">
                                            <div className="bg"  data-bg="images/all/experiences.jpg"></div>
                                            <div className="d-gr-sec"></div>
                                           
                                            <div className="listing-item-grid_title">
                                                <h3><a href="/blog/experiences">{t('Expériences')}</a></h3>
                                               
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-8">
                                        <div className="listing-item-grid">
                                            <div className="bg"  data-bg="images/all/coworking.png"></div>
                                            <div className="d-gr-sec"></div>
                                           
                                            <div className="listing-item-grid_title">
                                                <h3><a href="/blog/coworking">{t('Coworking')}</a></h3>
                                                
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>
                      </section>




                    <section style={{paddingTop:"30px",paddingBottom:"30px"}}>
                        <div className="container">
                            <div className="section-title">
                                <h2> {t('Témoignages')}</h2>
                                
                                <span className="section-separator"></span>
                                <p className='center'>{t('Témoignages_desc')}</p>
                            </div>
                        </div>
                        <div className="clearfix"></div>

                        <div className="testimonilas-carousel-wrap fl-wrap">
                            <div className="listing-carousel-button listing-carousel-button-next center"><i className="fas fa-caret-right"></i></div>
                            <div className="listing-carousel-button listing-carousel-button-prev center"><i className="fas fa-caret-left"></i></div>

                             <div className="testimonilas-carousel">
                                <div className="swiper-container">
                                    <div className="swiper-wrapper">
                                    {reviews.map((item,index) => {
                                      
                                       return (
                                           <AvisItem item={item} lang={currentlang} />
                                        );
                            
                                     })}
                                       

                                    </div>
                                </div>
                             </div>

                         </div>
                    </section>


 


                </div>

    <Modal show={show} onHide={handleClose} size="lg" className='modalhomexl800 '>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

        <div className="process-wrap fl-wrap process-wrap-home">

        <div class="section-title pad665456">
                                <h2> {t('Comment ça marche')} ?</h2>
                                 
                                <span class="section-separator"></span>
                                 
        </div>


                                <div>

                                     <img src="/images/7771847E-E873-4F69-97B1-9824AB3D916C.png" />
                                </div>

                                <div className='processhome990'>

                                   <a href="/how-it-works" class=" down-btn color3-bg"><i class="fal fa-video"></i> {t('Voir la vidéo')} </a>

                                </div>
                                
                            </div>

      

        </Modal.Body>
        <Modal.Footer>
          
          
        </Modal.Footer>
        </Modal>
        </div>
    );
}

export default Page;