import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import {getContentPage } from "../../actions/pages";

const Page = () => {
    const { slug } = useParams();
    
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [categorie, setCategorie] = useState([]);
    const { contents,partenaires } = useSelector(state => state.pages)
    const handleClose = () => setShow(false);

   
 
    const handleSubmit = (item) => {

        if(currentlang=='fr'){ 
          window.location.href="/detail/"+item.slug_fr+"/"+item.id
        }
        else{
           window.location.href="/detail/"+item.slug_en+"/"+item.id
        }

    }

   
   

    useEffect(() => {
     

        if(langset == false){

             const params={
               slug:slug,
               page:'blog'
              }


           return apiClient().post(`/getPageText`, params)
            .then((res) => {
                 
                        setBlogs(res.data?.data)

                        setCategorie(res.data?.categorie)
                         
            })


            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])

    return (
        <div className="contact pagecontent" >


           <section className="parallax-section single-par" data-scrollax-parent="true">
                        <div className="bg par-elem " data-bg="images/contact-investisseurs-header.png" data-scrollax="properties: { translateY: '30%' }" style={{backgroundImage: "url(images/contact-investisseurs-header.png)"}}></div>
                        <div className="overlay op7"></div>
                        <div className="container">
                            <div className="section-title center-align big-title">
                                <h2><span>


                                {
                                    categorie==1 ? t('Hôtels') : categorie==2 ? t('Restaurants & Bars') : categorie==3 ? t('Appartements & villas') : categorie==4 ? t('Expériences') :  t('Coworking')
                                }



                                </span></h2>
                                <span className="section-separator"></span>
                                 
                            </div>
                        </div>
                        <div className="header-sec-link">
                            <a href="javascript:void(0)" className="custom-scroll-link"> </a> 
                        </div>
           </section>


           <section id="sec1" data-scrollax-parent="true" style={{paddingBottom:"0"}}>
                        <div className="container">
                         
                           
                            
                            <div className="about-wrap">
                                <div className="row">

                                
                                        

                              {blogs.map((item,index) => {
                                       let newindex=index+1;
                                       return (

                                             <div key={newindex} className="assistant-item col-md-4 ">
                                               <article className="geodir-category-listing fl-wrap mr88">
                                                 <div className="geodir-category-img">
                                                   <a className="geodir-category-img-wrap fl-wrap"><img src="/images/team/2.png" />
                                                   <div className="center p33">
                                                   <div className="blog889" style={{backgroundImage:`url(${item.image })`,width:'100%',height:'100%'}}>
                                                   </div>
                                                   </div>  
                                                   </a>
                                                   <div className="geodir-category-opt"></div>
                                                   </div><div className="geodir-category-content fl-wrap title-sin_item">
                                                   <div className="geodir-category-content-title fl-wrap">
                                                   <div className="geodir-category-content-title-item">
                                                   <br/>
                                                   <h3 className="title-sin_map"><a className="upcase">{currentlang=='fr' ? item.title_fr : item.title_en } </a>
                                                    </h3>
                                                   </div></div><div className="geodir-category-text fl-wrap">
                                                   <p className="small-text">{currentlang=='fr' ? item.description_fr : item.description_en }...</p></div>
                                                   <div className="geodir-category-footer fl-wrap center">
                                                   <button onClick={()=>handleSubmit(item)}  className="view-profil-button green-bg">{t('Voir Détail')} </button>
                                                   </div>
                                                   </div>
                                                   </article>
                                                   </div>

                                       );


                            
                                })}

 
                                    

                                     
                                </div>
                            </div>
                            
                          
                        </div>
                    </section>


              
 





           

 
             
        </div>
    );
}

export default Page;