import { SET_CATEGORIES,GET_AVIS,SET_PARAINAGE,SET_REVIEWS,GET_WATCHERS,GET_WATCHER,SAVE_MISSION,Edit_MISSION,SET_RATE,SET_MESSAGES,SET_FILES,SET_INVOICES,GET_RECLAMATIONS,MISSIONS_REALISE,MISSIONS_VALIDATE,MISSION_ENCOURS,GET_DETAIL_MISSION,WATCHER_MISSION,SET_CONTENT_PAGE,LIST_MISSIONS,GET_MISSION,GET_PATENAIRES,GET_FAQ,SET_CURRENT_RESTAURENT,SET_HORAIRE_RESTAURENT } from "../constants/actions"
import { apiClient } from "./api"
 
export const getAllCategories = (params) => dispatch => {
    return apiClient().post(`/getMarque/`, params)
        .then((res) => {
           
            dispatch({type:SET_CATEGORIES,payload:res.data.data})
        })
}


export const getAllReviews = (params) => dispatch => {
    return apiClient().post(`/getReviews/`, params)
        .then((res) => {
          
            dispatch({type:SET_REVIEWS,payload:res.data.data})
        })
}
export const getWatchersHome = (params) => dispatch => {
    return apiClient().post(`/getWatcherHome/`, params)
        .then((res) => {

            dispatch({type:GET_WATCHERS,payload:res.data.data})
        })
}

export const getParainage = (params,currentlang, id_user,type_user,action,token,lnmsg,lnmsg2) => dispatch => {
    const data = {
        ...params,
        token:token,
        user:id_user,
        type_user:type_user,
        action:action,
        currentlang:currentlang,
        referer:'none'
        
    }
    return apiClient().post(`/getParainage`, data)
        .then((res) => {

            if(action=='save' ||  action=='remove'){
                if(res.data.success==1){
                     alert(lnmsg)
                     window.location.reload()
                }
                else{
                    alert(lnmsg2)
                    window.location.reload()
                }
            }
             console.log('ahmed')
             console.log(res.data?.data)

             dispatch({type:SET_PARAINAGE,payload:res.data?.data})
             
        })
}
export const getAvis = (params,id_user,type_user,action,token) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        type_user:type_user,
        action:action,
        referer:'none'
        
    }
    return apiClient().post(`/getAvis`, data)
        .then((res) => {

    
             console.log(res.data)
             dispatch({type:GET_AVIS,payload:res.data?.data})
             
        })
}
export const getReclamations = (params,id_user,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        type_user:type_user,
        action:action,
        referer:'none'
        
    }
    return apiClient().post(`/getReclamations`, data)
        .then((res) => {

            if(action=='save' ||  action=='remove'){
                if(res.data.success==1){
                     alert(lnmsg)
                     window.location.reload()
                }
                else{
                    alert("An error occurred please try again later.")
                    window.location.reload()
                }
            }
             
             dispatch({type:GET_RECLAMATIONS,payload:res.data?.data})
             
        })
}
export const setInvoices = (params,id_user,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        type:type_user,
        action:action,
        referer:'none'
        
    }
    return apiClient().post(`/setInvoices`, data)
        .then((res) => {
 
           
               dispatch({type:SET_INVOICES,payload:res.data?.data})
            
             
             
        })
}
export const setFiles = (params,id_user,to,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        to:to,
        type_user:type_user,
        action:action,
        referer:'none'
        
    }
    console.log(data)
    return apiClient().post(`/setFiles`, data)
        .then((res) => {
 
           console.log(res)
               dispatch({type:SET_FILES,payload:res.data?.data})
            
             
             
        })
}

export const setMessages = (params,id_user,to,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        to:to,
        type_user:type_user,
        action:action,
        referer:'none'
        
    }
    return apiClient().post(`/setMessages`, data)
        .then((res) => {
 
            if(action=='save'){
                if(res.data.success==1){
                     alert(lnmsg)
                     //window.location.reload()
                }
                else{
                    alert("An error occurred please try again later.")
                    window.location.reload()
                }
            }
            else if(action=='select'){
               dispatch({type:SET_MESSAGES,payload:res.data?.data})
            }
             
             
             
        })
}

export const setRate = (params,id_user,to,missionid,note,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        to:to,
        missionid:missionid,
        type_user:type_user,
        action:action,
        note:note,
        referer:'none'
        
    }
    return apiClient().post(`/setRate`, data)
        .then((res) => {
 
            if(action=='save'){
                if(res.data.success==1){
                     alert(lnmsg)
                     //window.location.reload()
                }
                else{
                    alert("An error occurred please try again later.")
                    window.location.reload()
                }
            }
            else if(action=='select'){
               dispatch({type:SET_RATE,payload:res.data?.data})
            }
             
             
             
        })
}

export const getWatcher = (params) => dispatch => {
    return apiClient().post(`/getWatcher`, params)
        .then((res) => {
           
            dispatch({type:GET_WATCHER,payload:res.data.data})
        })
}

export const getListWatcherBymission = (params) => dispatch => {
    const data = {
        ...params,
        referer:'none'
    }

    return apiClient().post(`/getWatcherByMission`, data)
        .then((res) => {
             if(res.data.success==false){
               window.location.href='/my-account/';
              }
              else{
                 dispatch({type:WATCHER_MISSION,payload:res.data.data})
              }
            
        })
}


export const getDetailMission = (params) => dispatch => {
    const data = {
        ...params,
        referer:'none'
    }

    return apiClient().post(`/getDetailMission`, data)
        .then((res) => {
            
             if(res.data.success==false){
               
                if(params.type=='user'){
                     window.location.href='/my-account/';
                }
                else{
                    window.location.href='/watchers/dashboard/';
                }
               
              }
              else{
                 dispatch({type:GET_DETAIL_MISSION,payload:res.data.data})
              }
            
        })
}

export const getPartenaires = (params) => dispatch => {
    return apiClient().post(`/getPartenaires/`, params)
        .then((res) => {
          
            dispatch({type:GET_PATENAIRES,payload:res.data.data})
        })
}
export const getFaq = (params) => dispatch => {
    return apiClient().post(`/getFoireQuestions/`, params)
        .then((res) => {
          
            dispatch({type:GET_FAQ,payload:res.data.data})
        })
}

 
export const getContentPage = (params,page) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none',
        page:page
    }

    return apiClient().post(`/getPageText`, data)
        .then((res) => {
            
            dispatch({type:SET_CONTENT_PAGE,payload:res.data.data})
        })
}




export const getListMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {
             
             dispatch({type:LIST_MISSIONS,payload:res.data?.data})
             
        })
}

export const getListEncoursMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {
             
             dispatch({type:MISSION_ENCOURS,payload:res.data?.data})
             
        })
}

export const getListValidateMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {

             dispatch({type:MISSIONS_VALIDATE,payload:res.data?.data})
             
        })
}

export const getListRealiseMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {
             
             dispatch({type:MISSIONS_REALISE,payload:res.data?.data})
             
        })
}


export const saveMission = (params,price,categorie,ville,user,token,currentlang) => dispatch => {
    const data = {
        ...params,
        token:token,
        referer:'none',
        origin_id:2,
        price:price,
        categorie:categorie,
        ville:ville,
        user:user,
        currentlang:currentlang
    }
    return apiClient().post(`/mission/save`, data)
        .then((res) => {
           
            if(res.data.success==false){
                alert(res.data?.message)
            }else{
                    // remove storage
                    window.location.href='/my-account/';
              
            }
        })
}

export const EditMission = (params,user,id_mission,token,type,profile,msg='') => dispatch => {

    const data = {
        ...params,
        token:token,
        referer:'none',
        type:type,
        user:user,
        profile:profile,
        id_mission:id_mission
    }
    return apiClient().post(`/mission/update`, data)
        .then((res) => {
                 
                 if(profile=='watcher'){
                     setTimeout(function(){
                         window.location.href='/watchers/dashboard/';
                     }, 2000);
                     
                 }

                 else{
                    if(type=='remove'){
                        alert(msg)
                    }

                    window.location.href='/my-account/';
                 }
            
                   
              
            
        })
}

 
export const getMissionById = (id,user,token) => dispatch => {
    return apiClient().get(`/getmission/${id}/${user}/${token}`)
        .then((res) => {
              if(res.data.success==false){
               window.location.href='/my-account/';
              }
              else{
                 dispatch({type:GET_MISSION,payload:res.data?.data})
              }
            
        })
}

//====================================================================================
export const getRestaurentById = (id) => dispatch => {
    return apiClient().get(`/products/${id}`)
        .then((res) => {
            
            dispatch({type:SET_CURRENT_RESTAURENT,payload:res.data})
        })
}
export const getRestaurentHoraires = (id) => dispatch => {
    const data = {
        id:id
    };
    return apiClient().post(`/getspatterns/`,data)
        .then((res) => {
            console.log("getspatterns", res.data.data)
            dispatch({type:SET_HORAIRE_RESTAURENT,payload:res.data.data})
        })
}