import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import {DashbordHeader,Dashbord } from "../../../components";
import { getParainage } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';

const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory()
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { parainages } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);

    useEffect(() => {
   
       
          const params={}
             dispatch(getParainage(params,currentlang,user.id,'watcher','select',user.emailToken,t('Nous avons bien reçu votre demande, Notre conseiller va vous contacter rapidement..')));
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
   
    
   const handleSubmit = async (e) => {
        e.preventDefault();


        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
       
        dispatch(getParainage(formDataObj,currentlang, user.id,'user','save',user.emailToken,t('Email a été envoyé avec succès'),t("Vous avez atteint la limite d'envoi d'e-mails")));


    }

 
    return (
        <div className="myccount  " >
            
            <DashbordHeader />

            <section className="gray-bg main-dashboard-sec dashboard detailmission" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord src='parrainage'/>
                                 
                            </div>


                            <div className='col-md-9'>

                                <div className='tab' id=''>

                                <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Parrainage')}</h3>
                                    <br/><br/>
                                 </div>



                                 


                                     <form onSubmit={handleSubmit} style={{padding:'0px'}}>


                                <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                       <div className="row">
                                            <div className="col-sm-12">
                                                <label>{t('Parrainez vos amis')}*</label>
                                                <input placeholder={t('Email')}  name="email" type="text" className="form-control" required/>                                              
                                            </div>
                                        </div>

                                    

                                        <div className="row">
                                            <div className="col-sm-12">
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> {t('Envoyer')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                                 <div className="dashboard-title   fl-wrap">
                                  
                                </div>

                                </form>



                                    <table  id="datatable_valides" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Code')}#</th>
                                        <th>{t('Email')}</th>
                                       
                                    </tr>
                                    </thead>
                                    <tbody>

                                  {parainages.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                           
                                      <tr>
                                      <td>{item.token}</td>
                                      <td>{item.email}</td>
                                      
                                      </tr>
                                        );
                            
                                     })}
                             
                                    
                                      
                                    </tbody>
                            </table>
                                </div>

                             
                             </div>


                              

                    </div>
                    
            </section>


        </div>
    );
}

export default Page;