import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import {DashbordHeader,DashbordMenu } from "../../../components";
import { useTranslation } from 'react-i18next';
import { getReclamations } from "../../../actions/pages";


const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { reclamations } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const history = useHistory()

    const handleSubmit = async (e) => {
        e.preventDefault();


        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
       
        dispatch(getReclamations(formDataObj,user.id,'watcher','save',user.emailToken,t('Nous avons bien reçu votre demande, Notre conseiller va vous contacter rapidement..')));


    }


    const removeReclam = (idrecla) => {
      
        const formData = {
           idrecla:idrecla
        }
       
        dispatch(getReclamations(formData,user.id,'watcher','remove',user.emailToken,t('Votre réclamation est bien été annulée')));


    }

     useEffect(() => {
        
       
        
        if(langset == false){

             const params={}
             dispatch(getReclamations(params,user.id,'watcher','select',user.emailToken));
 
             

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
 
    
 
    return (
        <div className="myccount  " >
            
            <DashbordHeader />

            <section className="gray-bg main-dashboard-sec dashboard detailmission" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='reclamations' />
                                 
                            </div>


                            <div className='col-md-9'>

                                <div className='tab' id=''>

                                <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Déposer une réclamation')} </h3>
                                    
                                </div>

                                <div className='row'>
                                  <div className='col-md-12'>
                                     <p className='para alert alert-success  center'>{t('Veuillez utiliser le formulaire ci-dessous afin de décrire votre problème..')}</p>
                                  </div>

                                </div>

                                
                                <form onSubmit={handleSubmit} style={{padding:'0px'}}>


                                <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                       <div className="row">
                                            <div className="col-sm-12">
                                                <label>{t('Objet')}*</label>
                                                <input   name="objet" type="text" className="form-control" required/>                                              
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{t('Description')} </label>
                                                 <textarea name='description'  rows="3" className='form-control' required></textarea>                                             
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> {t('Envoyer')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                                 <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Mes réclamations')} </h3>
                                    
                                </div>

                                </form><br/><br/>

                                    <table  id="datatable_valides" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref')}#</th>
                                        <th>{t('Objet')}</th>
                                        <th>{t('Description')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('status')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {reclamations.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                      <tr>
                                      <td>{item.ref}</td>
                                      <td>{item.objet}</td>
                                      <td>{item.description}</td>
                                      <td>{item.datereclamation}</td>
                                      <td>

                                         {
                                          item.status==1 ?

                                             <div className='badge alert-warning'>{t('En cours')}</div>
                                          :

                                          <div className='badge alert-success'>Traitée</div>



                                         }
                                         

                                        </td>
                                      <td>
                                          
                                       <div className="list-contr">
                                              
                                                <a href="javascript:void(0)" onClick={()=>removeReclam(item.id)} className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Supprimer')}><i class="fal fa-trash"></i></a>
                                        </div>


                                      </td>
                                      </tr>

                                        );
                            
                                      })}


 
                                      
                                    </tbody>
                            </table>
                                </div>

                             
                             </div>


                              

                    </div>
                    
            </section>


        </div>
    );
}

export default Page;