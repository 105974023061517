import React,{ useState,useEffect } from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../actions/api"
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { setMessages } from "../../actions/pages";
 


const Component = () => {
    const { user } = useSelector(state => state.user)
    const { contacts } = useSelector(state => state.pages)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [activeitem, setActiveItem] = useState(0);
    const [conversation, setConversation] = useState([]);
    const [activeUser, setActiveUser] = useState({});
    const [message, setMessage] = useState('');



    const handleClick = (item) => {
          
          setActiveItem(item.id)
          const data = {
        
                token:user.emailToken,
                id_user:user.id,
                to:item.id,
                type_user:user.type==2 ? 'watcher' : 'user',
                action:'get',
                referer:'none'
        
           }

          apiClient().post(`/setMessages`,data)
            .then((res) => {
                
                    if(res.data?.success){
                        console.log(res.data.data)
                        setConversation(res.data?.data)
                        setActiveUser(res.data?.user)
                    }
            })



    }
    const handleSubmit = (e) => {
           if(!message){
             return false;
           }

           
           const data = {
                message:message,
                token:user.emailToken,
                id_user:user.id,
                to:activeUser.id,
                type_user:user.type==2 ? 'watcher' : 'user',
                action:'save',
                referer:'none',
                forsave:1
        
           }
    

          apiClient().post(`/setMessages`,data)
            .then((res) => {
                
                    if(res.data?.success){
                        setConversation(res.data?.data) 

                        setMessage('')
                    }
            })
            

    }

      useEffect(() => {
     

        dispatch(setMessages({},user.id,'',user.type==2 ? 'watcher' : 'user','select',user.emailToken,''));
 
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

  

    return (

       <div className='messages'>
               <div className="dashboard-list-box fl-wrap">
                                    <div className="dashboard-header color-bg fl-wrap">
                                        <h3>Messages</h3>
                                    </div>
                                    <div className="chat-wrapper fl-wrap">
                                        <div className="row">
                                             
                                            <div className="col-sm-8" style={{paddingRight: "19px"}}>
                                                <div className="chat-box fl-wrap scroolmessage">


                                                 {
                                                    conversation.length == 0 && 
                                                      <div><h6 className='notfound'>{t('Aucun message trouvé')}..</h6></div>
                                                 }


                                    {conversation.map((item,index) => {
                                       let newindex=index+1;
                                       return (

                                            item.from==user.id ? 
                                            <div  key={newindex} className="chat-message chat-message_guest fl-wrap">
                                                        <div className="dashboard-message-avatar">
                                                            <img src={user.profil_picture} alt=""/>
                                                            <span className="chat-message-user-name cmun_sm">{user.prenom}</span>
                                                        </div>
                                                        <span className="massage-date">{item.date}</span>
                                                        <p>{item.message}</p>
                                             </div>

                                             :

                                              <div key={newindex} className="chat-message chat-message_user fl-wrap">
                                                        <div className="dashboard-message-avatar">
                                                            <img src={activeUser.profil_picture} alt=""/>
                                                            <span className="chat-message-user-name cmun_sm">{activeUser.prenom}</span>
                                                        </div>
                                                        <span className="massage-date">{item.date}</span>
                                                        <p>{item.message}</p>
                                               </div>


                                       );


                            
                                })}
                                                     
                                                   
                                                   
                                                   
                                                   
                                                 
 
 
                                                                                              
                                                </div>
                                                {
                                                  conversation.length > 0 &&

                                                    <div className="chat-widget_input fl-wrap">
                                                    <form   style={{padding:'0px'}}>

                                                        <textarea value={message} onChange={e => setMessage(e.target.value)}  placeholder={t('Votre Message')} required>{message}</textarea>                                                 
                                                        <button onClick={handleSubmit} type="button"><i className="fal fa-paper-plane"></i></button>

                                                    </form>
                                                   
                                                </div>
                                                }
                                                
                                            </div>
                                           

                                            <div className="col-sm-4">
                                                <div className="chat-contacts fl-wrap">

                                                
                                    {contacts.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                             

                                                    <a onClick={()=>handleClick(item.user)}  key={newindex} className={activeitem==item.user.id ? "chat-contacts-item chat-contacts-item_active" : "chat-contacts-item"} href="javascript:void(0)">
                                                        <div className="dashboard-message-avatar">
                                                            <img src={item.user.photo} alt="" />
                                                            <div className="message-counter">{item.nbnotread}</div>
                                                        </div>
                                                        <div className="chat-contacts-item-text">
                                                            <h4 style={{marginTop:"10px"}}>{item.user.prenom} {item.user.nom}</h4>
                                                             
                                                            
                                                        </div>
                                                    </a>

                                        );


                            
                                     })}
                                                    

                                                  
 
                                                                                                   
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                </div>
       </div>
    );
}

export default Component;