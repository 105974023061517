import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,AssistantItem } from "../../../components";
import { useTranslation } from 'react-i18next';

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const history = useHistory();
    const { t, i18n } = useTranslation();
     const [langset, setLangset] = useState(false);
     const currentlangStorage = localStorage.getItem("currentlang");
     const currentlang=i18n.language;
    useEffect(() => {
 
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
    }, [])
    
 
    return (
        <div className="devenirassistant checkmail login" >
             
            <section className="pd889 mg889" id="sec1"><div className="container big-container">
             


                <div className='row'>

                      <div className='col-md-2'></div>
                      <div className='col-md-8'>

                       <div className="text-center mx-auto my-5 col-lg-12">
                         
                        <div  className="wpb_animate_when_almost_visible wpb_appear appear  wpb_start_animation animated" >
                            <h4 className="center-txt light">{t('Nous avons envoyé un e-mail à votre adresse')} </h4>
                            <h7 className="center-txt semi-bold">{t('Veuillez consulter vos emails et cliquer sur le lien afin de valider votre inscription sur mywatcher.fr')}</h7>
                            <br/>
                            <br/>
                            <div className="mailTab">
                                <img src="/images/watcher-email.png" alt="" title=""/>
                            </div>
                        </div>

                       </div>

                       </div>
                       <div className='col-md-2'></div>

 


                </div>


     

             </div>

             

         


            </section>
             
        </div>
    );

}

export default Page;