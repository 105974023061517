import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,AssistantItem, DashbordHeader} from "../../../components";
import { updateInfoUser } from "../../../actions/user";
import { useTranslation } from 'react-i18next';

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [wait, setWait] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setWait(true)
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(updateInfoUser(formDataObj,user.id,'user'));
    
    }

      const goback =  () => {
        window.location.href='/my-account/'
    
    }


     useEffect(() => {
      
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
 
    return (
        <div className="myccount editprofile " >
           <DashbordHeader src={'u'} />
     

            <section className="gray-bg main-dashboard-sec" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord src='editprofil' />
                                 
                            </div>

                    
                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Modifier mon profile')} </h3>
                                    <br/><br/>
                                   <form  onSubmit={handleSubmit} >
                                    <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label>{t('Prénom')} <i className="fal fa-user"></i></label>
                                                <Form.Control placeholder="Robert" name="prenom" type="text" className="form-control" defaultValue={user.prenom} required/>                                              
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{t('Nom')} <i className="fal fa-user"></i></label>
                                                 <Form.Control placeholder="Plafond" name="nom" type="text" className="form-control" defaultValue={user.nom} required/>                                              
                                            </div>
                                           
                                            <div className="col-sm-6">
                                                <label>{t('Téléphone')}<i className="far fa-phone"></i>  </label>
                                                <Form.Control type="text" name='tel' placeholder="+7(123)987654" required defaultValue={user.telephone} />                                                
                                            </div>
                                            <div className="col-sm-6">
                                                <label> {t('Ville')} <i className="fas fa-map-marker"></i>  </label>
                                                <Form.Control type="text"  name='ville' placeholder="Paris.."  required  defaultValue={user.ville}/>                                                
                                            </div>
                                             <div className="col-sm-6">
                                                <label>{t('Email')}<i className="far fa-envelope"></i>  </label>
                                                <Form.Control type="email" name='email' placeholder="JessieManrty@domain.com" required defaultValue={user.email}  />                                                
                                            </div>
                                            <div className="col-sm-6">
                                                <label> {t('Mot de passe')} <i className="far fa-globe"></i>  </label>
                                                <Form.Control type="password" placeholder="********"  name='motdepasse'  />                                                
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>



                                  <div className="row">
                                           <div className="col-sm-6">
                                                 <button type="button" onClick={()=>goback()} className="btn float-btn color2-bg bt77878 "> {t('Retour')} <i className="fas fa-caret-right"></i></button>
                                            </div>

                                            <div className="col-sm-6">
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> { wait == false ? t('Modifier') : t('Modification effectuée..') }  <i className="fas fa-caret-right"></i></button>
                                            </div>
                                   </div>

                                    </form>
                                 </div>
                              </div>
 

                              

                         </div>
                    </div>
            </section>


        </div>
    );
}

export default Page;