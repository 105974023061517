import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,AssistantItem, DashbordHeader} from "../../../components";
import { EditMission,getMissionById } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import moment from 'moment'
import 'moment/locale/fr'


const Page = () => {

    const dispatch = useDispatch();
    const { slug } = useParams()
    const { user } = useSelector(state => state.user)
    const { mission } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [wait, setWait] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())


          // calcule timing
          if(mission.ville=='Paris, France'|| mission.ville=='Paris' || mission.ville=='Paris,France'){
                
                let startTime=moment(mission?.date_mission_order+' '+mission?.time).format('YYYY-MM-DD HH:mm')
                let endTime=formDataObj['datepicker-here-time']
                

                if(!endTime.includes('-')){
                    endTime=moment(endTime+' '+formDataObj.time,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')

                }
                else{

                    endTime=moment(endTime+' '+formDataObj.time,'YYYY-DD-MM HH:mm').format('YYYY-MM-DD HH:mm')

                }



                var duration = moment.duration(moment(endTime).diff(startTime));
                var hours = duration.asHours();
              

                 if(hours > 12){

                    alert("La date de votre réservation n'est pas correcte")
                    return false;

                 }

            }
 
          
          setWait(true)
        dispatch(EditMission(formDataObj,user.id,slug,user.emailToken,'update','user'));
    
    }

      const goback =  () => {
        window.location.href='/my-account/'
    
    }
     useEffect(() => {
        
      
        if(langset == false){
            dispatch(getMissionById(slug,user.id,user.emailToken));
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
 
    return (
        <div className="myccount editprofile " >
           <DashbordHeader src={'u'} />
     

            <section className="gray-bg main-dashboard-sec" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord />
                                 
                            </div>

                    
                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Modifier Mission')} </h3>
                                    <br/><br/>
                                   <form  onSubmit={handleSubmit} >
                                    <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label>{t('Catégorie')}</label>
                                                <Form.Control readOnly    type="text" className="form-control" defaultValue={mission.label} />                                              
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{t('Ville')} </label>
                                                 <Form.Control readOnly type="text" className="form-control" defaultValue={mission.ville} />                                              
                                            </div>
                                           
                                            <div className="col-sm-6">
                                                <label>{t('Adresse')}</label>
                                                {
                                                  (mission.ville=='Paris, France'|| mission.ville=='Paris' || mission.ville=='Paris,France') ?
                                                       <Form.Control  type="text" name='adresse' required  style={{backgroundColor: "#FFF"}}  defaultValue={mission.adresse} />
                                                   :
                                                   <Form.Control  type="text" readOnly name='adresse' required  style={{backgroundColor: "#FFF"}}  defaultValue={mission.adresse} />

                                                }
                                                                                                
                                            </div>

                                            <div className="col-sm-6">
                                                <label>{t('Prix')} (€)</label>
                                                <Form.Control readOnly  name='price' type="number" min={mission.price} max={1000}  style={{backgroundColor: "#FFF  !important"}} required  defaultValue={mission.price} />  

                                            </div>

                                            <div className="col-sm-6">
                                                <label>{t('Durée')}</label>
                                                <select readOnly className='form-control' name='duree' style={{backgroundColor: "#FFF  !important"}}>
                                                  <option  selected={mission.duree=='15mn' ? 'true':false} value='15mn'>15mn</option>
                                                  <option  selected={mission.duree=='30mn' ? 'true':false} value='30mn'>30mn</option>
                                                  <option  selected={mission.duree=='45mn' ? 'true':false} value='45mn'>45mn</option>
                                                  <option  selected={mission.duree=='1h' ? 'true':false} value='1h'>1h</option>
                                                </select>                                               
                                            </div>

                                         
                                    

                                             

                                            <div className="col-sm-6">
                                                <label> {t('Date')} </label>
                                                {
                                                  (mission.ville=='Paris, France'|| mission.ville=='Paris' || mission.ville=='Paris,France') ?
                                                   <Form.Control  style={{backgroundColor: "#FFF"}} required type="text" placeholder={t('Date souhaitée')+"*"} name="datepicker-here-time" value={mission?.date_mission_order && moment(mission?.date_mission_order,'YYYY-MM-DD').format('DD/MM/YYYY')}/>        
                                                   :
                                                   <Form.Control  style={{backgroundColor: "#FFF"}} required type="text" placeholder={t('Date souhaitée')+"*"} name="datepicker-here-time" readOnly value={mission?.date_mission_order && moment(mission?.date_mission_order,'YYYY-MM-DD').format('DD/MM/YYYY')}/>        
                                                }
                                                                                        
                                            </div>
                                             <div className="col-sm-6"></div>

                                             <div className="col-sm-6">
                                                <label> {t('Heure')} </label>
                                                {
                                                  (mission.ville=='Paris, France'|| mission.ville=='Paris' || mission.ville=='Paris,France') ?
                                                    <div className='blockbg55 timing'>
                                                           
                                                            
                                                           <input type='time' name='time' className='' required defaultValue={mission.time} />

                                                         </div>      
                                                   :
                                                     <div className='blockbg55 timing'>
                                                       
                                                          
                                                           <input type='time' name='time' className='' required defaultValue={mission.time} />

                                                      </div>       
                                                }
                                                                                        
                                            </div>


                                           


                                            <div className="col-sm-12">
                                                <label> {t('Que souhaitez vous faire ? Décrivez ce que dois réaliser l’assistant...')} </label>
                                                <textarea   defaultValue={mission.description} style={{marginBottom:"20px",backgroundColor: "#FFF"}} required name='description' cols="40" rows="2" >{mission.description}</textarea>                                               
                                            </div>
                                          
                                        </div>
                                      
                                    </div>
                                </div>



                                  <div className="row">
                                   <div className="col-sm-6">
                                                 <button type="button" onClick={()=>goback()} className="btn float-btn color2-bg bt77878 "> {t('Retour')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                                            <div className="col-sm-6">
                                                 <button  type="submit" className="btn float-btn color2-bg btn9876 "> { wait == false ? t('Modifier') : t('Mission modifiée..') } <i className="fas fa-caret-right"></i></button>
                                            </div>
                                   </div>

                                    </form>
                                 </div>
                              </div>
 

                              

                         </div>
                    </div>
            </section>


        </div>
    );
}

export default Page;