import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import {getContentPage } from "../../actions/pages";
const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
     const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
     const { contents } = useSelector(state => state.pages)
   
 
    const handleSubmit = (e) => {
        e.preventDefault();
        setShow(true)
    }

     const handleGoTo = (e) => {
        e.preventDefault();
         
    }
     useEffect(() => {
        
        dispatch(getContentPage({},'RGPD'));
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])
   
    return (
        <div className="faq" >


           <section className="parallax-section single-par" data-scrollax-parent="true">
                        <div className="bg par-elem " data-bg="images/3A4812A4-4F29-4940-8650-B99DF7F24CFE.png" data-scrollax="properties: { translateY: '30%' }" style={{backgroundImage: "url(images/3A4812A4-4F29-4940-8650-B99DF7F24CFE.png)"}}></div>
                        <div className="overlay op7"></div>
                        <div className="container">
                            <div className="section-title center-align big-title">
                                <h2><span>{t('RGPD')}</span></h2>
                                <span className="section-separator"></span>
                                 
                            </div>
                        </div>
                        <div className="header-sec-link">
                            <a href="javascript:void(0)" className="custom-scroll-link"> </a> 
                        </div>
           </section>




           <section className="small-padding" >
                <div className="container">

                <div className='row'>
                     <div className='col-md-12'>
                          
                       {
                                            contents.contenu &&
                                            <p className="default-desc margright mrgtop" dangerouslySetInnerHTML={{__html: currentlang=='fr' ? contents.contenu.fr : contents.contenu.en }}></p>
                                        }


                     </div>

                  </div>

                
                                   
                </div>
           </section>

 
             
        </div>
    );
}

export default Page;