import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import {DashbordHeader,Dashbord } from "../../../components";
import { getAvis } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';

const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory()
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { avis } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);

    useEffect(() => {
   
       
          const params={}
             dispatch(getAvis(params,user.id,'client','select',user.emailToken));
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
   
    

    const returnReviews= (note) => {
          
         return(

        
            note==1 ?
             <div><i class="fas fa-star"></i></div>
             : note == 2 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 3 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 4 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 5 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             :
             <div></div>

          
       )
    }
 

 
    return (
        <div className="myccount  " >
            
            <DashbordHeader />

            <section className="gray-bg main-dashboard-sec dashboard detailmission" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord src='commentaires'/>
                                 
                            </div>


                            <div className='col-md-9'>

                                <div className='tab' id=''>

                                <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('avis')}</h3>
                                   
                                 </div>





                       <div className="list-single-main-item_content fl-wrap">
                                                <div className="reviews-comments-wrap" style={{width: '100%'}}>

                                    { avis.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                        
                                                 <div key={newindex} className="reviews-comments-item" style={{paddingLeft: '0px'}}>
                                                        <div className="review-comments-avatar">
                                                           
                                                            <div className='ps9988' style={{backgroundImage:`url(${item.profil_picture })`}}>

                                                              </div> 
                                                        </div>
                                                        <div className="reviews-comments-item-text fl-wrap">
                                                            <div className="reviews-comments-header fl-wrap">
                                                                <h4><a href="javascript:void(0)" >{item.prenom} {item.nom}</a> - {t('Mission')} Ref: #{item.ref} </h4>
                                                                <div className="review-score-user">
                                                                    <span className="review-score-user_item center">{item.note}</span>
                                                                    <div className="listing-rating card-popup-rainingvis" data-starrating2="4">
                                                {returnReviews(item.note)}
                               
                                                </div>
                                                                </div>
                                                            </div>
                                                            <p>{item.commentaire}</p>
                                                            <div className="reviews-comments-item-footer fl-wrap">
                                                                <div className="reviews-comments-item-date"><span><i className="far fa-calendar-check"></i>{item.date}</span></div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                             

                                        );
                            
                                   })}

                                                     
                                                
                                                    
                                            
                                                                                                                     
                                                </div>
                                            </div>


                                 


                                    



                                     
                                </div>

                             
                             </div>


                              

                    </div>
                    
            </section>


        </div>
    );
}

export default Page;