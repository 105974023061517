import React,{useEffect,useState} from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../actions/api";
import { useParams } from "react-router";

const Page = () => {

    const dispatch = useDispatch();
  
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const { slug } = useParams()

  const handleSubmit = (e) => {
        e.preventDefault();

        window.location.href="/watchers/login"
        
        
    }

      useEffect(() => {
        
        if(langset == false){

        const params={
            token:slug
        }
        apiClient().post(`/validate/token`,params)
           .then((res) => {
            console.log(res)
              setLoading(false)
              if(res.data.success==false){
                     setError(true)
              }else{
                 setSuccess(true)
              }
               
           })


            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
        
 

    }, [])


    return (

    <div className="devenirassistant checkmail login" >
             
            <section className="pd889 mg889" id="sec1"><div className="container big-container">
             
                <div className='row'>

                      <div className='col-md-2'></div>
                      <div className='col-md-8'>


                      {
                        loading== true &&
                         <div style={{textAlign:"center",marginTop:"100px",marginBottom:"100px"}} >
                                <img src="/images/loading.gif"  />
                         </div>
                      }


                      {
                        error== true &&

                       <div className="text-center mx-auto my-5 col-lg-12">
                        <div  className="wpb_animate_when_almost_visible wpb_appear appear  wpb_start_animation animated" >
                            
                            <div className="mailTab">
                                <img src="/images/pngwing.com.png" alt="" title="" style={{width:"200px"}}/>
                            </div>
                             <br/>
                            <br/>
                            <h4 className="center-txt light">{t('Token invalide ou expirée !')} </h4>
                             
                           
                        </div>

                       </div>
                          
                      }

                      {
                        success== true &&

                       <div className="text-center mx-auto my-5 col-lg-12">
                        <div  className="wpb_animate_when_almost_visible wpb_appear appear  wpb_start_animation animated" >
                            
                            <div className="mailTab">
                                <img src="/images/validation-150x150.png" alt="" title=""/>
                            </div>
                            <br/>
                            <br/>
                            <h6 className="center-txt semi-bold">{t('Félicitations, votre inscription sur mywatcher.fr est validée !')}</h6>
                           <br/>
                           <button onClick={handleSubmit} class="btn color2-bg url_btn float-btn bggreen99">{t('Identifiez-vous')}<i class="fal fa-user"></i></button>
                            
                            
                        </div>

                       </div>
                          
                      }

                      




                       </div>
                       <div className='col-md-2'></div>

                </div>
             </div>
            </section>
             
        </div>
   
    );
}

export default Page;