import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import {DashbordHeader,DashbordMenu } from "../../../components";
import { setInvoices } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';

const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory()
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { invoices } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);

    useEffect(() => {
   
       
         dispatch(setInvoices({},user.id,user.type==2 ? 'watcher' : 'user','select',user.emailToken,''));
     
        
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
   
    

 
    return (
        <div className="myccount  " >
            
            <DashbordHeader />

            <section className="gray-bg main-dashboard-sec dashboard detailmission" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='factures'/>
                                 
                            </div>


                            <div className='col-md-9'>

                                <div className='tab' id=''>

                                <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Mes factures')} </h3>
                                    <br/><br/>
                                 </div>


                                    <table  id="datatable_valides" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref facture')}#</th>
                                        <th>{t('Ref mission')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('status')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                  {invoices.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                           
                                      <tr>
                                      <td>{item.reffacture}</td>
                                      <td>{item.refmission}</td>
                                      <td>{item.date}</td>
                                      <td><div className='badge alert-success'>{t('Validé')}</div></td>
                                      <td>
                                          
                                       <div className="list-contr">
                                                
      
                                                <a href={currentlang=='fr'? item.url : item.url_en} download className="green-bg tolt center" data-microtip-position="left" data-tooltip={t('Télécharger la facture')}><i class="fal fa-file-pdf"></i></a>
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}
                             
                                    
                                      
                                    </tbody>
                            </table>
                                </div>

                             
                             </div>


                              

                    </div>
                    
            </section>


        </div>
    );
}

export default Page;