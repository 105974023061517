import { VIDER_PANIER,ADD_PRODUCT_BASKET,REMOVE_PRODUCT_BASKET,UPDATE_QTY,GET_DATA_CART,GET_TOTAL_CART,OBJECT_REDUCTION } from "../constants/actions";

const INITIAL_STATE = {
    data: [],
    total:0,
    reduction:{voucher:"",discount:0}
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADD_PRODUCT_BASKET :
            return {...state,data:[...state.data,action.payload]}
        case OBJECT_REDUCTION :
                return {...state,reduction:action.payload}
        case REMOVE_PRODUCT_BASKET :
            return {...state,data:state.data.filter(el => el.id !== action.payload),reduction:{voucher:"",discount:0}}
        case VIDER_PANIER :
                return {...state,data:[],reduction:{voucher:"",discount:0}}
        case GET_DATA_CART :
            return state;
        case GET_TOTAL_CART :
                let totalprice=0;
                state.data.forEach(element => {
                    totalprice = totalprice + element.product.price*(element.qty);
                    element.extrasdatas.forEach(elementp => {
                        totalprice = totalprice + elementp.price*(element.qty);
                    });
                });
                return {...state,total:totalprice}
        case UPDATE_QTY :
                return {...state,data:state.data.map(
                    (content, i) => content.id === action.payload ? {...content, qty: action.qty}
                                                                  : content
                                                                )
                                                            }
        default:
            return state;
    }
}
export default reducer;