import React,{ useState, useEffect } from "react";
import { logo,google_play,app_store,logof,linkedin,face,insta } from "../../assets/images";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import { registerUser,loginUser,loginUserFacebook } from "../../actions/user";
import { apiClient } from "../../actions/api";
import FacebookLogin from 'react-facebook-login';


const Component = ({ item }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const currentlang=i18n.language;
    const currentlangStorage = localStorage.getItem("currentlang");
    const [langset, setLangset] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [email, setEmail] = useState('');

    const [socials, setSocials] = useState({});

    const handlerconnexion = async (e) =>{
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())
         dispatch(loginUser(formDataObj,'user',currentlang));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(registerUser(formDataObj,'user',currentlang));
       
    }


        const componentClicked =  () => {
        
       
    }

     const responseFacebook = (response) => {

    const membreData = {
        email: response.email,
    }

    return apiClient().post(`/check/user`, membreData)
          .then((res) =>
           { 

            if(res.data.success==true){
               
                 dispatch(loginUserFacebook(res))
            }
            else{

                 const params = {
                  email: response.email,
                  fname: response.first_name,
                  lname: response.last_name,
                  tel:'',
                  password:'EY778UEIEU76567'
                 }

                  dispatch(registerUser(params,'user',currentlang));

            }

         })

 
       
} 




    useEffect(() => {
     
 
        if(langset == false){

           


          return apiClient().get(`/getSocialMedia`, {})
          .then((res) =>
           { 
            console.log(res.data.data)

            setSocials(res.data.data)

         })


            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])


    return (
        <footer className="main-footer fl-wrap footer">
            
              
                <div className="footer-inner   fl-wrap">
                    <div className="container">
                        <div className="row">
                           
                            <div className="col-md-4">
                                
                            </div>
                             
                            <div className="col-md-4">
                                <div className="footer-widget fl-wrap">
                                    <h3>{t('Entreprise')}</h3>
                                    <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix">
                                                <a href="/about-us" className="">{t('Qui sommes nous')}</a>
                                                <a href="/how-it-works" className="">{t('Comment ça marche')}</a>
                                                <a href="/contact" className="">{t('Nous contacter')}</a>
                                                <a href="/watchers/login" className="">{t('Espace assistant')}</a>
                                                <a href="/talk-about-us" className="">{t('Ils parlent de nous')}</a>
                                            </li>
                                            
                                            
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                             
                            <div className="col-md-4">
                                <div className="footer-widget fl-wrap ">
                                    <h3>{t('Aide')}</h3>
                                    <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix">
                                                <a href="/rgbd" target='_blank' className="">{t('Conditions générales')}</a>
                                                <a href="/legal-notice" target='_blank' className="">{t('Mentions légales')}</a>
                                                <a href="/faq" className="">{t('FAQ')}</a>
                                               <a href="/join-the-team" className="">{t('Rejoindre la team')}</a>
                                               <a href="/centre-reservation" className="">{t('Service réservation')}</a>
                                                

                                            </li>
                                            
                                            
                                        </ul>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                          
                        </div>
                    </div>
                     
                    <div className="footer-bg" data-ran="4"><span className="footer-bg-pin"></span><span className="footer-bg-pin"></span><span className="footer-bg-pin"></span><span className="footer-bg-pin footer-bg-pin-vis"></span></div>
                    <div className="footer-wave">
                        <svg viewBox="0 0 100 25">
                            <path fill="#fff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
                        </svg>
                    </div>
                    
                </div>
               
                <div className="sub-footer  fl-wrap">
                    <div className="container">
                        <div className="copyright"> © 2checkhotels 2022 . All rights reserved.</div>
                        
                        <div className="subfooter-nav">
                               <div className="footer-social">
                                            <span>{t('Trouvez-nous sur :')} </span>
                                            <ul className="no-list-style nop66">

                                            

                                 

                                           <li><a href={socials.Facebook ? socials.Facebook :"javascript:void(0)" } target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                           <li><a href={socials.Twitter ? socials.Twitter :"javascript:void(0)" } target="_blank"><i className="fab fa-twitter"></i></a></li>
                                           <li><a href={socials.Instagram ? socials.Instagram :"javascript:void(0)" } target="_blank"><i className="fab fa-instagram"></i></a></li>
                                           <li><a href={socials.linkedin ? socials.linkedin :"javascript:void(0)" } target="_blank"><i className="fab fa-linkedin"></i></a></li>
                                              <li><a href={socials.Youtube ? socials.Youtube :"javascript:void(0)" } target="_blank"><i className="fab fa-youtube"></i></a></li>

                             
                                                
                                                
                                            </ul>
                                        </div>
                        </div>
                    </div>
                </div>


             <div className="main-register-wrap modal">
                <div className="reg-overlay"></div>
                <div className="main-register-holder tabs-act">
                    <div className="main-register fl-wrap  modal_main">
                        <div className="main-register_title"></div>
                        <div className="close-reg center"><i className="fal fa-times"></i></div>
                        <ul className="tabs-menu fl-wrap no-list-style">
                            <li className="current"><a href="#tab-2"><i className="fal fa-user-plus"></i> {t('Inscription')}</a></li>
                            <li ><a href="#tab-1"><i className="fal fa-sign-in-alt"></i> {t('Connexion')}</a></li>
                            
                        </ul>
                                              
                        <div className="tabs-container">
                            <div className="tab">
                                
                                <div id="tab-1" className="tab-content ">
                                    <div className="custom-form">
                                        <form  name="registerform" onSubmit={handlerconnexion}  >
                                            <label>{t('Email')} <span>*</span> </label>
                                            <input name="email" type="email" required/>
                                            <label >{t('Mot de passe')} <span>*</span> </label>
                                            <input name="password" type="password" required   / >
                                            <button type="submit"  className="btn float-btn color2-bg " > {t('Connexion')} <i className="fas fa-caret-right"></i></button>
                                            <div className="clearfix"></div>
                                            <div className="filter-tags">
                                                <input id="check-a3" type="checkbox" name="check"  />
                                                <label for="check-a3">{t('Se souvenir de moi')}</label>
                                            </div>
                                        </form>
                                        <div className="lost_password">
                                            <a  href="/password/user">{t('Mot de passe oublié ?')}</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="tab">
                                    <div id="tab-2" className="tab-content first-tab">
                                        <div className="custom-form">
                                            <form  onSubmit={handleSubmit}  name="registerform" className="main-register-form" id="main-register-form2">
                                                
                                                <label >{t('Prénom')} <span>*</span> </label>
                                                <input name="fname" type="text"   required />
                                                <label >{t('Nom')} <span>*</span> </label>
                                                <input name="lname" type="text"    required />
                                                <label >{t('Téléphone')} <span>*</span> </label>
                                                <span className='elem990'>{t("Vous devez indiquer un numéro de téléphone afin d'être contacté par votre watcher")}</span>
                                                <input name="tel" type="text"   required />

                                                <label>{t('Email')}  <span>*</span></label>
                                                <input name="email" type="email"  required/>
                                                <label >{t('Mot de passe')} <span>*</span></label>
                                                <input name="password" type="password"  required />
                                                <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check" required />
                                                    <label for="check-a2">{t('En créant un compte, j’accepte les')} <a target="_blanc" href="https://2checkhotels.com/rgbd">{t('CGU')}</a></label>
                                                </div>
                                                <div className="clearfix"></div>
                                               
                                                <div className="clearfix"></div>
                                                <button type="submit"      className="btn float-btn color2-bg"> {t('Je créée mon compte')}  <i className="fas fa-caret-right"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div className="log-separator fl-wrap center"><span>{t('OU')}</span></div>
                            <div className="soc-log fl-wrap">
                              
                                <a style={{display:'none'}} href="javascript:void(0)" className="facebook-log"> Facebook</a>
                               <FacebookLogin
                                        appId="1810271582506013"
                                        autoLoad={false}
                                        fields="first_name,last_name,email"
                                        textButton={t('Connexion avec Facebook')}
                                        onClick={componentClicked}
                                         callback={responseFacebook} />
                            </div>
                            <div className="wave-bg">
                                <div className='wave -one'></div>
                                <div className='wave -two'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <input type='hidden' value={t('Recherche')} id='btntextserach' />
            </footer>
    );
}

export default Component;